import { ENUM_APP_CATEGORY_NAME, ENUM_APP_NAME } from "@/configs/enums/enum";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import axios, { type AxiosInstance } from "axios";

export const axiosClient = (params: { isFile: boolean }, type: ENUM_APP_NAME, cat?: ENUM_APP_CATEGORY_NAME): AxiosInstance => {
  const baseURL = type == ENUM_APP_NAME.POLICE_COMMAND_CENTER ? import.meta.env.VITE_TTP_COMMAND_CENTER_API : cat == ENUM_APP_CATEGORY_NAME.AGENT ? import.meta.env.VITE_TTP_POLICE_API : import.meta.env.VITE_TTP_TOURIST_API;
  const instance = axios.create({
    baseURL,
    ...(params.isFile
      ? {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
      : {}),
  });

  instance.interceptors.request.use(async (config) => {
    try {
      const token = await getToken();
      config.headers.Authorization = token ? `Bearer ${token}` : null;
    } catch (e) {
      console.error('getIdToken failed', e)
    }

    return config;
  });

  return instance;
};

const getToken = async (): Promise<string> => {
  try {
    // first attemp
    const { token } = await FirebaseAuthentication.getIdToken();
    return token
  } catch (e) {
    console.error('1st attemp to getIdToken', e)
  }

  await new Promise<void>((resolve) => {
    FirebaseAuthentication.addListener('authStateChange', () => {
      // resolve()
      resolve()
    })
  })


  try {
    // second attemp
    const { token } = await FirebaseAuthentication.getIdToken();
    return token
  } catch (e) {
    console.error('2nd attemp to getIdToken', e)
    throw e
  }
}
