<script lang="ts">
import { defineComponent } from "vue";
import { Geolocation } from "@capacitor/geolocation";
import { useIncidentFormStore } from "./stores/useIncidentFormStore";
import { useIncidentFormStore as useComponentIncidentFormStore } from "../../../components/CommandCenter/stores/useIncidentFormStore";
import { useIncidentAssigneeFormStore } from "./stores/useIncidentAssigneeFormStore";

import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { assignValues, deleteKeysFromObject, intersectJSON } from "@/configs/helpers/MainHelper";
import type { $enum, $number, $string, $text } from "@/configs/types/Shared/typeShare";
import { type ENUM_GENDER, type ENUM_INCIDENT_STATUS, type ENUM_PRIORITY } from "@/configs/enums/enum";
import type { typeGetIncident } from "@/configs/types/Incident/typeIncident";

import BadgeDefaultComponent from "@/components/Forms/Badge/BadgeDefaultComponent.vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import ButtonStyle2Component from "@/components/Forms/Button/ButtonStyle2Component.vue";
import FormCheckBoxDefaultComponent from "@/components/Forms/CheckBox/FormCheckBoxDefaultComponent.vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import FormRadioDefaultComponent from "@/components/Forms/Radio/FormRadioDefaultComponent.vue";
import FormSelectBoxDefaultComponent from "@/components/Forms/SelectBox/FormSelectBoxDefaultComponent.vue";
import FormTextareaDefaultComponent from "@/components/Forms/Textarea/FormTextareaDefaultComponent.vue";
import PopupAssignTextareaComponent from "@/components/Popups/PopupAssignTextareaComponent.vue";
import PopupCancelComponent from "@/components/Popups/PopupCancelComponent.vue";
import PopupCautionComponent from "@/components/Popups/PopupCautionComponent.vue";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";
import { useSharedStore } from "@/stores/PoliceCommandCenter/useSharedStore";
import { fetchFile } from "@/configs/fetches/PoliceCommandCenter/fetchFile";
import { useAuthStore } from "../Authentication/stores/useAuthStore";
import ChatDialogComponent from "@/components/CommandCenter/ChatDialog.vue";
import { PERMISSION_INCIDENT_CREATE } from "@/configs/permission";
import { io } from "socket.io-client";

export default defineComponent({
  name: "IncidentManageView",
  components: {
    ButtonDefaultComponent,
    ChatDialogComponent,
  },
  data() {
    return {
      componentName: this.$options.name,
      timer: null as any,
    };
  },
  computed: {
    assigneeFormComponents() {
      return {
        form: {
          textarea: {
            detail: {
              component: FormTextareaDefaultComponent,
              props: {
                classTagName: "w-full",
                isSpecial: false,
                isVisible: true,
                isError: (this.incidentAssigneeFormStore.error[this.incidentAssigneeFormStore.isRequired.detail.name] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.incidentAssigneeFormStore.isRequired.detail.is,
                textError: this.$t(this.incidentAssigneeFormStore.isRequired.detail.placeholder),
                title: this.$t(this.incidentAssigneeFormStore.isRequired.detail.label),
                name: this.incidentAssigneeFormStore.isRequired.detail.name,
                placeholder: this.$t(this.incidentAssigneeFormStore.isRequired.detail.placeholder),
                value: this.incidentAssigneeFormStore?.result?.detail,
                functionProp: () => {},
              } as typeof FormTextareaDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.incidentAssigneeFormStore.result.detail = value;
              },
            },
          },
          radio: {
            status: {
              component: FormRadioDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: !this.$route.params.id || (this.$route.params.id && this.isDisableStatus_IncidentFormStore_StatusField() && this.incidentFormStore.action == this.$enums.ENUM_MUTATION.ACCEPT),
                isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.status.name] || "").length > 0,
                isIcon: false,
                isDisable: !this.$inArray(this.incidentFormStore.action, [this.$enums.ENUM_MUTATION.ACCEPT, null]),
                isRequired: true,
                textError: this.$t(this.incidentFormStore.isRequired.status.placeholder),
                title: this.$t(this.incidentFormStore.isRequired.status.label),
                placeholder: this.$t(this.incidentFormStore.isRequired.status.placeholder),
                value: this.incidentFormStore?.result?.status,
                items: this.masterDataStore.itemStatus(Boolean(this.$route.params.id)),
                hiddenValues: [this.$enums.ENUM_INCIDENT_STATUS.REJECTED],
                name: this.incidentFormStore.isRequired.status.name,
              } as typeof FormRadioDefaultComponent.props,
              onUpdateValue: (value: ENUM_INCIDENT_STATUS) => {
                this.incidentFormStore.result.status = value;
                this.incidentAssigneeFormStore.result.status = value;
              },
            },
          },
          popup: {
            assigneeSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.incidentFormStore.isSuccess || this.incidentAssigneeFormStore.isProgressSuccess,
                hasCancel: false,
                textTitle: this.$t(this.incidentFormStore.result.status == this.$enums.ENUM_INCIDENT_STATUS.REJECTED ? "popup.success.reject_title" : this.incidentFormStore.result.status == this.$enums.ENUM_INCIDENT_STATUS.CLOSED ? "popup.success.closed_title" : this.$route.params.id ? "popup.success.title" : this.incidentFormStore.result.status ? "popup.success.waiting_title" : "popup.success.title"),
                textDetail: this.$t(this.incidentFormStore.result.status == this.$enums.ENUM_INCIDENT_STATUS.REJECTED ? "popup.success.history_detail" : this.incidentFormStore.result.status == this.$enums.ENUM_INCIDENT_STATUS.CLOSED ? "popup.success.history_detail" : this.$route.params.id ? "popup.success.history_detail" : this.incidentFormStore.result.status ? "popup.success.history_detail" : "popup.success.detail"),
                functionProp: () => {
                  this.incidentFormStore.isSuccess = false;
                  this.incidentAssigneeFormStore.isProgressSuccess = false;
                  this.$router.push({
                    name: "IncidentNoticeView",
                    params: { id: this.$route.params.id ? this.$route.params.id : this.incidentFormStore.id },
                  });
                  this.reset();
                },
                functionCancelProp: async () => {
                  this.incidentFormStore.isSuccess = false;
                  this.incidentAssigneeFormStore.isProgressSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            assigneeCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.incidentFormStore.isSuccess || this.incidentAssigneeFormStore.isProgressSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.error_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: async () => {
                  this.incidentFormStore.isSuccess = false;
                  this.incidentAssigneeFormStore.isProgressSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
            reponsibleSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.incidentAssigneeFormStore.isResponsibleSuccess,
                textTitle: this.$t("popup.success.receive_title"),
                textDetail: this.$t("popup.success.receive_detail"),
                hasCancel: false,
                functionProp: () => {
                  this.incidentAssigneeFormStore.isResponsibleSuccess = false;
                  this.$router.push({
                    name: "IncidentNoticeView",
                    params: { id: this.$route.params.id ? this.$route.params.id : this.incidentFormStore.id },
                  });
                  this.reset();
                },
                functionCancelProp: async () => {
                  this.incidentAssigneeFormStore.isResponsibleSuccess = false;
                },
              },
            },
            reponsibleCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.incidentAssigneeFormStore.isResponsibleSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.recipient_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: async () => {
                  this.incidentAssigneeFormStore.isResponsibleSuccess = false;
                },
              },
            },
          },
        },
      };
    },
    defaultFormComponents() {
      return {
        alert: {
          backgroundCheck: {
            component: PopupSuccessComponent,
            props: {
              isOpen: this.incidentFormStore.isBackgroundCheck,
              textTitle: this.$t("popup.success.no_criminal"),
              textDetail: "",
              functionProp: () => {
                this.incidentFormStore.isBackgroundCheck = false;
              },
              functionCancelProp: async () => {
                this.incidentFormStore.isBackgroundCheck = false;
              },
            } as typeof PopupSuccessComponent.props,
          },
        },
        form: {
          input: {
            guest: {
              name: {
                component: FormInputDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.guest.name.name] || "").length > 0,
                  isIcon: false,
                  isDisable: Boolean(
                    this.incidentFormStore?.result?.responsibleID === null ||
                      this.$inArray(this.incidentFormStore.result.informerType, [
                        this.$enums.ENUM_INFORMER_TYPE.ANONYMOUS,
                        // this.$enums.ENUM_INFORMER_TYPE.TOURIST,
                      ]) ||
                      this.loading()
                  ),
                  isRequired: this.incidentFormStore.isRequired.guest.name.is,
                  textError: this.$t(this.incidentFormStore.isRequired.guest.name.placeholder),
                  title: 'ชื่อนักท่องเที่ยว',
                  placeholder: this.$t(this.incidentFormStore.isRequired.guest.name.placeholder),
                  value: this.incidentFormStore?.result?.guest?.name,
                  name: this.incidentFormStore?.isRequired?.guest?.name?.name,
                  isKeydownText: true,
                } as typeof FormInputDefaultComponent.props,
                onUpdateValue: (value: $string) => {
                  this.incidentFormStore.result.guest.name = value;
                },
                click: () => {
                  this.incidentFormStore.isDialogName = false;
                  this.incidentFormStore.isDialogEmail = false;
                  this.incidentFormStore.isDialogContactNumber = true;
                  this.incidentFormStore.isDialogIdentificationNumber = false;
                },
                input: async (e: any) => {
                  clearTimeout(this.timer);
                  this.timer = setTimeout(async () => {
                    this.findInformer(e, "name");
                  }, 1000);
                },
                blur: () => {
                  this.$window.setTimeout(() => {
                    this.incidentFormStore.isDialogName = false;
                    this.incidentFormStore.isDialogEmail = false;
                    this.incidentFormStore.isDialogContactNumber = false;
                    this.incidentFormStore.isDialogIdentificationNumber = false;
                  }, 1000);
                },
              },
              contactNumber: {
                component: FormInputDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.guest.contactNumber.name] || "").length > 0,
                  isIcon: false,
                  isDisable: Boolean(
                    this.incidentFormStore?.result?.responsibleID === null ||
                      this.$inArray(this.incidentFormStore.result.informerType, [
                        this.$enums.ENUM_INFORMER_TYPE.ANONYMOUS,
                        // this.$enums.ENUM_INFORMER_TYPE.TOURIST,
                      ]) ||
                      this.loading()
                  ),
                  isRequired: false,
                  textError: this.$t(this.incidentFormStore.isRequired.guest.contactNumber.placeholder),
                  title: this.$t(this.incidentFormStore.isRequired.guest.contactNumber.label),
                  placeholder: this.$t(this.incidentFormStore.isRequired.guest.contactNumber.placeholder),
                  value: this.incidentFormStore?.result?.guest?.contactNumber,
                  name: this.incidentFormStore.isRequired.guest.contactNumber.name,
                  isKeydownNumber: true,
                  maxLength: 25,
                } as typeof FormInputDefaultComponent.props,
                onUpdateValue: (value: $string) => {
                  this.incidentFormStore.result.guest.contactNumber = value;
                },
                click: () => {
                  this.incidentFormStore.isDialogName = false;
                  this.incidentFormStore.isDialogEmail = false;
                  this.incidentFormStore.isDialogContactNumber = true;
                  this.incidentFormStore.isDialogIdentificationNumber = false;
                },
                input: async (e: any) => {
                  clearTimeout(this.timer);
                  this.timer = setTimeout(async () => {
                    this.findInformer(e, "contactNumber");
                  }, 1000);
                },
                blur: () => {
                  this.$window.setTimeout(() => {
                    this.incidentFormStore.isDialogName = false;
                    this.incidentFormStore.isDialogEmail = false;
                    this.incidentFormStore.isDialogContactNumber = false;
                    this.incidentFormStore.isDialogIdentificationNumber = false;
                  }, 1000);
                },
              },
              email: {
                component: FormInputDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.guest.email.name] || "").length > 0,
                  isIcon: false,
                  isDisable: Boolean(
                    this.incidentFormStore?.result?.responsibleID === null ||
                      this.$inArray(this.incidentFormStore.result.informerType, [
                        this.$enums.ENUM_INFORMER_TYPE.ANONYMOUS,
                        // this.$enums.ENUM_INFORMER_TYPE.TOURIST,
                      ]) ||
                      this.loading()
                  ),
                  isRequired: this.incidentFormStore.isRequired.guest.email.is,
                  textError: this.$t(this.incidentFormStore.isRequired.guest.email.placeholder),
                  title: this.$t(this.incidentFormStore.isRequired.guest.email.label),
                  placeholder: this.$t(this.incidentFormStore.isRequired.guest.email.placeholder),
                  value: this.incidentFormStore?.result?.guest?.email,
                  name: this.incidentFormStore.isRequired.guest.email.name,
                } as typeof FormInputDefaultComponent.props,
                onUpdateValue: (value: $string) => {
                  this.incidentFormStore.result.guest.email = value;
                },
                click: () => {
                  this.incidentFormStore.isDialogName = false;
                  this.incidentFormStore.isDialogEmail = true;
                  this.incidentFormStore.isDialogContactNumber = false;
                  this.incidentFormStore.isDialogIdentificationNumber = false;
                },
                input: async (e: any) => {
                  clearTimeout(this.timer);
                  this.timer = setTimeout(async () => {
                    this.findInformer(e, "email");
                  }, 1000);
                },
                blur: () => {
                  this.$window.setTimeout(() => {
                    this.incidentFormStore.isDialogName = false;
                    this.incidentFormStore.isDialogEmail = false;
                    this.incidentFormStore.isDialogContactNumber = false;
                    this.incidentFormStore.isDialogIdentificationNumber = false;
                  }, 1000);
                },
              },
              identificationNumber: {
                component: FormInputDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.guest.identificationNumber.name] || "").length > 0,
                  isIcon: false,
                  isDisable: Boolean(
                    this.incidentFormStore?.result?.responsibleID === null ||
                      this.$inArray(this.incidentFormStore.result.informerType, [
                        this.$enums.ENUM_INFORMER_TYPE.ANONYMOUS,
                        // this.$enums.ENUM_INFORMER_TYPE.TOURIST,
                      ]) ||
                      this.loading()
                  ),
                  isRequired: this.incidentFormStore.isRequired.guest.identificationNumber.is,
                  textError: this.$t(this.incidentFormStore.isRequired.guest.identificationNumber.placeholder),
                  title: this.$t(this.incidentFormStore.isRequired.guest.identificationNumber.label),
                  placeholder: this.$t(this.incidentFormStore.isRequired.guest.identificationNumber.placeholder),
                  value: this.incidentFormStore?.result?.guest?.identificationNumber,
                  name: this.incidentFormStore.isRequired.guest.identificationNumber.name,
                  maxLength: 25,
                } as typeof FormInputDefaultComponent.props,
                onUpdateValue: (value: $string) => {
                  this.incidentFormStore.result.guest.identificationNumber = value;
                },
                click: () => {
                  this.incidentFormStore.isDialogName = false;
                  this.incidentFormStore.isDialogEmail = false;
                  this.incidentFormStore.isDialogContactNumber = false;
                  this.incidentFormStore.isDialogIdentificationNumber = true;
                },
                input: async (e: any) => {
                  clearTimeout(this.timer);
                  this.timer = setTimeout(async () => {
                    this.findInformer(e, "identificationNumber");
                  }, 1000);
                },
                blur: () => {
                  this.$window.setTimeout(() => {
                    this.incidentFormStore.isDialogName = false;
                    this.incidentFormStore.isDialogEmail = false;
                    this.incidentFormStore.isDialogContactNumber = false;
                    this.incidentFormStore.isDialogIdentificationNumber = false;
                  }, 1000);
                },
              },
              emergencyContact: {
                name: {
                  component: FormInputDefaultComponent,
                  props: {
                    isSpecial: false,
                    isVisible: true,
                    isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.guest.emergencyContact.name.name] || "").length > 0,
                    isIcon: false,
                    isDisable: Boolean(
                      this.incidentFormStore?.result?.responsibleID === null ||
                        this.$inArray(this.incidentFormStore.result.informerType, [
                          this.$enums.ENUM_INFORMER_TYPE.ANONYMOUS,
                          // this.$enums.ENUM_INFORMER_TYPE.TOURIST,
                        ]) ||
                        this.loading()
                    ),
                    isRequired: this.incidentFormStore.isRequired.guest.emergencyContact.name.is,
                    textError: this.$t(this.incidentFormStore.isRequired.guest.emergencyContact.name.placeholder),
                    title: 'ชื่อผู้ติดต่อในกรณีฉุกเฉิน / ผู้แจ้งเหตุ',
                    placeholder: this.$t(this.incidentFormStore.isRequired.guest.emergencyContact.name.placeholder),
                    value: this.incidentFormStore?.result?.guest?.emergencyContact?.name,
                    name: this.incidentFormStore.isRequired.guest.emergencyContact.name.name,
                    isKeydownText: true,
                  } as typeof FormInputDefaultComponent.props,
                  onUpdateValue: (value: $string) => {
                    this.incidentFormStore.result.guest.emergencyContact.name = value;
                  },
                },
                contactNumber: {
                  component: FormInputDefaultComponent,
                  props: {
                    isSpecial: false,
                    isVisible: true,
                    isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.guest.emergencyContact.contactNumber.name] || "").length > 0,
                    isIcon: false,
                    isDisable: Boolean(
                      this.incidentFormStore?.result?.responsibleID === null ||
                        this.$inArray(this.incidentFormStore.result.informerType, [
                          this.$enums.ENUM_INFORMER_TYPE.ANONYMOUS,
                          // this.$enums.ENUM_INFORMER_TYPE.TOURIST,
                        ]) ||
                        this.loading()
                    ),
                    isRequired: false,
                    textError: this.$t(this.incidentFormStore.isRequired.guest.emergencyContact?.contactNumber.placeholder),
                    title: this.$t(this.incidentFormStore.isRequired.guest.emergencyContact?.contactNumber.label),
                    placeholder: this.$t(this.incidentFormStore.isRequired.guest.emergencyContact?.contactNumber.placeholder),
                    value: this.incidentFormStore?.result?.guest?.emergencyContact?.contactNumber,
                    name: this.incidentFormStore.isRequired.guest.emergencyContact?.contactNumber.name,
                    isKeydownNumber: true,
                    maxLength: 25,
                  } as typeof FormInputDefaultComponent.props,
                  onUpdateValue: (value: $string) => {
                    this.incidentFormStore.result.guest.emergencyContact.contactNumber = value;
                  },
                },
                identificationNumber: {
                  component: FormInputDefaultComponent,
                  props: {
                    isSpecial: false,
                    isVisible: true,
                    isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.guest.emergencyContact.identificationNumber.name] || "").length > 0,
                    isIcon: false,
                    isDisable: Boolean(
                      this.incidentFormStore?.result?.responsibleID === null ||
                        this.$inArray(this.incidentFormStore.result.informerType, [
                          this.$enums.ENUM_INFORMER_TYPE.ANONYMOUS,
                          // this.$enums.ENUM_INFORMER_TYPE.TOURIST,
                        ]) ||
                        this.loading()
                    ),
                    isRequired: this.incidentFormStore.isRequired.guest.emergencyContact?.identificationNumber.is,
                    textError: this.$t(this.incidentFormStore.isRequired.guest.emergencyContact?.identificationNumber.placeholder),
                    title: this.$t(this.incidentFormStore.isRequired.guest.emergencyContact?.identificationNumber.label),
                    placeholder: this.$t(this.incidentFormStore.isRequired.guest.emergencyContact?.identificationNumber.placeholder),
                    value: this.incidentFormStore?.result?.guest?.emergencyContact?.identificationNumber,
                    name: this.incidentFormStore.isRequired.guest.emergencyContact?.identificationNumber.name,
                    maxLength: 25,
                  } as typeof FormInputDefaultComponent.props,
                  onUpdateValue: (value: $string) => {
                    this.incidentFormStore.result.guest.emergencyContact.identificationNumber = value;
                  },
                },
              },
            },
            information: {
              incidentCode: {
                component: FormInputDefaultComponent,
                props: {
                  maxLength: 4,
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.information.incidentCode.name] || "").length > 0,
                  isIcon: false,
                  isDisable: (this.$route.params.id && this.incidentFormStore?.result?.responsibleID === null) || this.loading(),
                  isRequired: this.incidentFormStore.isRequired.information?.incidentCode.is,
                  textError: this.$t(this.incidentFormStore.isRequired.information?.incidentCode.placeholder),
                  title: this.$t(this.incidentFormStore.isRequired.information?.incidentCode.label),
                  placeholder: this.$t(this.incidentFormStore.isRequired.information?.incidentCode.placeholder),
                  value: this.incidentFormStore?.result?.information?.incidentCode,
                  name: this.incidentFormStore.isRequired.information?.incidentCode.name,
                } as typeof FormInputDefaultComponent.props,
                onUpdateValue: async (value: $string) => {
                  this.incidentFormStore.result.information.incidentCode = value;

                  if (String(value)?.substring(0, 2).length >= 2) {
                    const foundItem = this.masterDataStore.itemIncidentCategories().find((item) => {
                      return item?.value?.includes(String(value)?.substring(0, 2));
                    });
                    if (foundItem) {
                      this.incidentFormStore.result.information.categoryCode = String(value)?.substring(0, 2);

                      this.masterDataStore.loadingTopicCode = true;
                      this.masterDataStore.incidentTopics = [];
                      this.incidentFormStore.result.information.topicCode = "";
                      this.masterDataStore.categoryCode = this.incidentFormStore.result.information.categoryCode;
                      await this.masterDataStore.listIncidentTopic(this.incidentFormStore.result.information.categoryCode);
                      this.masterDataStore.loadingTopicCode = false;

                      if (String(value)?.substring(0, 4).length >= 4) {
                        const foundItem = this.masterDataStore.itemIncidentTopics().find((item) => {
                          return item?.value?.includes(String(value)?.substring(0, 4));
                        });
                        if (foundItem) this.incidentFormStore.result.information.topicCode = String(value)?.substring(0, 4);
                        else this.incidentFormStore.result.information.topicCode = "";
                      }
                    } else {
                      this.incidentFormStore.result.information.categoryCode = "";
                      this.masterDataStore.loadingTopicCode = true;
                      this.masterDataStore.incidentTopics = [];
                    }
                  }
                },
              },
              location: {
                component: FormInputDefaultComponent,
                props: {
                  isSpecial: true,
                  isVisible: true,
                  isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.information.location.name] || "").length > 0,
                  isIcon: false,
                  isDisable: false,
                  isRequired: this.incidentFormStore.isRequired.information.location.is,
                  textError: this.$t(this.incidentFormStore.isRequired.information.location.placeholder),
                  title: this.$t(this.incidentFormStore.isRequired.information.location.label),
                  placeholder: this.$t(this.incidentFormStore.isRequired.information.location.placeholder),
                  value: this.incidentFormStore?.result?.information?.location,
                  name: this.incidentFormStore.isRequired.information.location.name,
                } as typeof FormInputDefaultComponent.props,
                onUpdateValue: (value: $string) => {
                  this.incidentFormStore.result.information.location = value;
                },
              },
            },
          },
          select: {
            guest: {
              nationality: {
                component: FormSelectBoxDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.guest.nationality.name] || "").length > 0,
                  isIcon: false,
                  isDisable: Boolean(
                    this.incidentFormStore?.result?.responsibleID === null ||
                      this.$inArray(this.incidentFormStore.result.informerType, [
                        this.$enums.ENUM_INFORMER_TYPE.ANONYMOUS,
                        // this.$enums.ENUM_INFORMER_TYPE.TOURIST,
                      ]) ||
                      this.loading()
                  ),
                  isRequired: this.incidentFormStore.isRequired.guest.nationality.is,
                  textError: this.$t(this.incidentFormStore.isRequired.guest.nationality.placeholder),
                  title: this.$t(this.incidentFormStore.isRequired.guest.nationality.label),
                  placeholder: this.$t(this.incidentFormStore.isRequired.guest.nationality.placeholder),
                  value: this.incidentFormStore?.result?.guest?.nationality,
                  items: this.masterDataStore.itemNationalities(),
                  name: this.incidentFormStore.isRequired.guest.nationality.name,
                } as typeof FormSelectBoxDefaultComponent.props,
                onUpdateValue: (value: $string) => {
                  this.incidentFormStore.result.guest.nationality = value;
                },
              },
              country: {
                component: FormSelectBoxDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.guest.country.name] || "").length > 0,
                  isIcon: false,
                  isDisable: Boolean(
                    this.incidentFormStore?.result?.responsibleID === null ||
                      this.$inArray(this.incidentFormStore.result.informerType, [
                        this.$enums.ENUM_INFORMER_TYPE.ANONYMOUS,
                        // this.$enums.ENUM_INFORMER_TYPE.TOURIST,
                      ]) ||
                      this.loading()
                  ),
                  isRequired: this.incidentFormStore.isRequired.guest.country.is,
                  textError: this.$t(this.incidentFormStore.isRequired.guest.country.placeholder),
                  title: this.$t(this.incidentFormStore.isRequired.guest.country.label),
                  placeholder: this.$t(this.incidentFormStore.isRequired.guest.country.placeholder),
                  value: this.incidentFormStore?.result?.guest?.country,
                  items: this.masterDataStore.itemNationalities(),
                  name: this.incidentFormStore.isRequired.guest.country.name,
                } as typeof FormSelectBoxDefaultComponent.props,
                onUpdateValue: (value: $string) => {
                  this.incidentFormStore.result.guest.country = value;
                },
              },
              gender: {
                component: FormSelectBoxDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.guest.gender.name] || "").length > 0,
                  isIcon: false,
                  isDisable: Boolean(
                    this.incidentFormStore?.result?.responsibleID === null ||
                      this.$inArray(this.incidentFormStore.result.informerType, [
                        this.$enums.ENUM_INFORMER_TYPE.ANONYMOUS,
                        // this.$enums.ENUM_INFORMER_TYPE.TOURIST,
                      ]) ||
                      this.loading()
                  ),
                  isRequired: this.incidentFormStore.isRequired.guest.gender.is,
                  textError: this.$t(this.incidentFormStore.isRequired.guest.gender.placeholder),
                  title: this.$t(this.incidentFormStore.isRequired.guest.gender.label),
                  placeholder: this.$t(this.incidentFormStore.isRequired.guest.gender.placeholder),
                  value: this.incidentFormStore?.result?.guest?.gender,
                  items: this.masterDataStore.itemGenders(),
                  name: this.incidentFormStore.isRequired.guest.gender.name,
                } as typeof FormSelectBoxDefaultComponent.props,
                onUpdateValue: (value: ENUM_GENDER | $enum) => {
                  this.incidentFormStore.result.guest.gender = value;
                },
              },
            },
            information: {
              friendlyCategoryCode: {
                component: FormSelectBoxDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.information.friendlyCategoryCode.name] || "").length > 0,
                  isIcon: false,
                  isDisable: true,
                  isRequired: false,
                  textError: this.$t(this.incidentFormStore.isRequired.information.friendlyCategoryCode.placeholder),
                  title: this.$t(this.incidentFormStore.isRequired.information.friendlyCategoryCode.label),
                  placeholder: this.$t(this.incidentFormStore.isRequired.information.friendlyCategoryCode.placeholder),
                  value: this.incidentFormStore?.result?.information?.friendlyCategoryCode,
                  items: this.masterDataStore.itemIncidentFriendlyCategories(),
                  name: this.incidentFormStore.isRequired.information.friendlyCategoryCode.name,
                  functionProp: () => {},
                } as typeof FormSelectBoxDefaultComponent.props,
                onUpdateValue: (value: $string) => {
                  this.incidentFormStore.result.information.categoryCode = value;
                },
              },
              categoryCode: {
                component: FormSelectBoxDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.information.categoryCode.name] || "").length > 0,
                  isIcon: false,
                  isDisable: (this.$route.params.id && this.incidentFormStore?.result?.responsibleID === null) || this.masterDataStore.loadingCategoryCode || this.loading(),
                  isRequired: this.incidentFormStore.isRequired.information.categoryCode.is,
                  textError: this.$t(this.incidentFormStore.isRequired.information.categoryCode.placeholder),
                  title: this.$t(this.incidentFormStore.isRequired.information.categoryCode.label),
                  placeholder: this.$t(this.incidentFormStore.isRequired.information.categoryCode.placeholder),
                  value: this.incidentFormStore?.result?.information?.categoryCode,
                  items: this.masterDataStore.itemIncidentCategories(),
                  name: this.incidentFormStore.isRequired.information.categoryCode.name,
                  functionProp: async (value: any) => {
                    this.masterDataStore.loadingTopicCode = true;
                    this.masterDataStore.incidentTopics = [];
                    this.incidentFormStore.result.information.incidentCode = "";
                    this.incidentFormStore.result.information.topicCode = "";
                    this.masterDataStore.categoryCode = value;
                    await this.masterDataStore.listIncidentTopic(value);
                    this.masterDataStore.loadingTopicCode = false;
                  },
                } as typeof FormSelectBoxDefaultComponent.props,
                onUpdateValue: (value: $string) => {
                  this.incidentFormStore.result.information.categoryCode = value;
                },
              },
              topicCode: {
                component: FormSelectBoxDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.information.topicCode.name] || "").length > 0,
                  isIcon: false,
                  isDisable: (this.$route.params.id && this.incidentFormStore?.result?.responsibleID === null) || this.masterDataStore.loadingTopicCode || this.loading(),
                  isRequired: this.incidentFormStore.isRequired.information.topicCode.is,
                  textError: this.$t(this.incidentFormStore.isRequired.information.topicCode.placeholder),
                  title: this.$t(this.incidentFormStore.isRequired.information.topicCode.label),
                  placeholder: this.$t(this.incidentFormStore.isRequired.information.topicCode.placeholder),
                  value: this.incidentFormStore?.result?.information?.topicCode,
                  items: this.masterDataStore.itemIncidentTopics(),
                  name: this.incidentFormStore.isRequired.information.topicCode.name,
                } as typeof FormSelectBoxDefaultComponent.props,
                onUpdateValue: (value: $string) => {
                  this.incidentFormStore.result.information.topicCode = value;
                  this.incidentFormStore.result.information.incidentCode = value;
                },
              },
              provinceID: {
                component: FormSelectBoxDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.information.provinceID.name] || "").length > 0,
                  isIcon: false,
                  isDisable: (this.$route.params.id && this.incidentFormStore?.result?.responsibleID === null) || this.masterDataStore.loadingProvince || this.loading(),
                  isRequired: this.incidentFormStore.isRequired.information.provinceID.is,
                  textError: this.$t(this.incidentFormStore.isRequired.information.provinceID.placeholder),
                  title: this.$t(this.incidentFormStore.isRequired.information.provinceID.label),
                  placeholder: this.$t(this.incidentFormStore.isRequired.information.provinceID.placeholder),
                  value: this.$checkNullAndConvert(this.incidentFormStore?.result?.information?.provinceID),
                  items: this.masterDataStore.itemProvinces(),
                  name: this.incidentFormStore.isRequired.information.provinceID.name,
                  functionProp: async (value: any) => {
                    this.masterDataStore.loadingDistrict = true;
                    this.masterDataStore.loadingSubDistrict = true;
                    this.masterDataStore.loadingStations = true;
                    this.incidentFormStore.result.information.districtID = null;
                    this.incidentFormStore.result.information.subDistrictID = null;
                    this.incidentFormStore.result.responsibleAreaCode = null;

                    this.masterDataStore.districts = [];
                    this.masterDataStore.subDistricts = [];
                    this.masterDataStore.stations = [];
                    this.masterDataStore.provinceID = value

                    await Promise.all([
                      this.masterDataStore.listDistrict(value),
                      this.masterDataStore.listStations(value, 0),
                      this.masterDataStore.listExternalDepartments(value),
                    ]);

                    this.masterDataStore.loadingDistrict = false;
                    this.masterDataStore.loadingStations = false;

                    if (this.masterDataStore.stations.length === 1) {
                      const [{ code }] = this.masterDataStore.stations;
                      this.incidentFormStore.result.responsibleAreaCode = code;
                    }
                  },
                } as typeof FormSelectBoxDefaultComponent.props,
                onUpdateValue: (value: $number) => {
                  this.incidentFormStore.result.information.provinceID = value;
                },
              },
              districtID: {
                component: FormSelectBoxDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.information.districtID.name] || "").length > 0,
                  isIcon: false,
                  isDisable: (this.$route.params.id && this.incidentFormStore?.result?.responsibleID === null) || this.masterDataStore.loadingDistrict || this.loading(),
                  isRequired: this.incidentFormStore.isRequired.information.districtID.is,
                  textError: this.$t(this.incidentFormStore.isRequired.information.districtID.placeholder),
                  title: this.$t(this.incidentFormStore.isRequired.information.districtID.label),
                  placeholder: this.$t(this.incidentFormStore.isRequired.information.districtID.placeholder),
                  value: this.$checkNullAndConvert(this.incidentFormStore?.result?.information?.districtID),
                  items: this.masterDataStore.itemDistricts(),
                  name: this.incidentFormStore.isRequired.information.districtID.name,
                  functionProp: async (value: any) => {
                    this.masterDataStore.loadingSubDistrict = true;
                    this.masterDataStore.loadingStations = true;
                    this.incidentFormStore.result.information.subDistrictID = null;
                    this.incidentFormStore.result.responsibleAreaCode = null;

                    this.masterDataStore.subDistricts = [];
                    this.masterDataStore.stations = [];

                    this.masterDataStore.districtID = value;

                    await Promise.all([
                      this.masterDataStore.listSubDistrict(this.masterDataStore.provinceID || 0, value),
                      this.masterDataStore.listStations(this.masterDataStore.provinceID || 0, value),
                    ]);

                    this.masterDataStore.loadingSubDistrict = false;
                    this.masterDataStore.loadingStations = false;

                    if (this.masterDataStore.stations.length === 1) {
                      const [{ code }] = this.masterDataStore.stations;
                      this.incidentFormStore.result.responsibleAreaCode = code;
                    }
                  },
                } as typeof FormSelectBoxDefaultComponent.props,
                onUpdateValue: (value: $number) => {
                  this.incidentFormStore.result.information.districtID = value;
                },
              },
              subDistrictID: {
                component: FormSelectBoxDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.information.subDistrictID.name] || "").length > 0,
                  isIcon: false,
                  isDisable: (this.$route.params.id && this.incidentFormStore?.result?.responsibleID === null) || this.masterDataStore.loadingSubDistrict || this.loading(),
                  isRequired: this.incidentFormStore.isRequired.information.subDistrictID.is,
                  textError: this.$t(this.incidentFormStore.isRequired.information.subDistrictID.placeholder),
                  title: this.$t(this.incidentFormStore.isRequired.information.subDistrictID.label),
                  placeholder: this.$t(this.incidentFormStore.isRequired.information.subDistrictID.placeholder),
                  value: this.$checkNullAndConvert(this.incidentFormStore?.result?.information?.subDistrictID),
                  items: this.masterDataStore.itemSubDistricts(),
                  name: this.incidentFormStore.isRequired.information.subDistrictID.name,
                } as typeof FormSelectBoxDefaultComponent.props,
                onUpdateValue: (value: $number) => {
                  this.incidentFormStore.result.information.subDistrictID = value;
                },
              },
              priority: {
                component: FormSelectBoxDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.information.priority.name] || "").length > 0,
                  isIcon: false,
                  isDisable: (this.$route.params.id && this.incidentFormStore?.result?.responsibleID === null) || this.loading(),
                  isRequired: this.incidentFormStore.isRequired.information.priority.is,
                  textError: this.$t(this.incidentFormStore.isRequired.information.priority.placeholder),
                  title: this.$t(this.incidentFormStore.isRequired.information.priority.label),
                  placeholder: this.$t(this.incidentFormStore.isRequired.information.priority.placeholder),
                  value: this.incidentFormStore?.result?.information?.priority,
                  items: this.masterDataStore.itemInformationPriority(),
                  name: this.incidentFormStore.isRequired.information.priority.name,
                } as typeof FormSelectBoxDefaultComponent.props,
                onUpdateValue: (value: ENUM_PRIORITY | $enum) => {
                  this.incidentFormStore.result.information.priority = value;
                },
              },
            },
            responsibleAreaCode: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.responsibleAreaCode.name] || "").length > 0,
                isIcon: false,
                isDisable: !this.ableToSetResponsibleArea || this.loading(),
                isRequired: this.incidentFormStore.isRequired.responsibleAreaCode.is,
                textError: this.$t(this.incidentFormStore.isRequired.responsibleAreaCode.placeholder),
                title: this.$t(this.incidentFormStore.isRequired.responsibleAreaCode.label),
                placeholder: this.$t(this.incidentFormStore.isRequired.responsibleAreaCode.placeholder),
                value: this.incidentFormStore?.result?.responsibleAreaCode,
                items: this.masterDataStore.itemStations(),
                name: this.incidentFormStore.isRequired.responsibleAreaCode.name,
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.incidentFormStore.result.responsibleAreaCode = value;
              },
            },
            externalDepartmentID: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.externalDepartmentID.name] || "").length > 0,
                isIcon: false,
                isDisable: !this.ableToSetExternalDepartmentID || this.loading(),
                isRequired: this.incidentFormStore.isRequired.externalDepartmentID.is,
                textError: this.$t(this.incidentFormStore.isRequired.externalDepartmentID.placeholder),
                title: this.$t(this.incidentFormStore.isRequired.externalDepartmentID.label),
                placeholder: this.$t(this.incidentFormStore.isRequired.externalDepartmentID.placeholder),
                value: this.incidentFormStore?.result?.externalDepartmentID,
                items: this.masterDataStore.itemExternalDepartments(),
                name: this.incidentFormStore.isRequired.externalDepartmentID.name,
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.incidentFormStore.result.externalDepartmentID = value;
              },
            },
          },
          textarea: {
            information: {
              detail: {
                component: FormTextareaDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.information.detail.name] || "").length > 0,
                  isIcon: false,
                  isDisable: (this.$route.params.id && this.incidentFormStore?.result?.responsibleID === null) || this.loading(),
                  isRequired: this.incidentFormStore.isRequired.information.detail.is,
                  textError: this.$t(this.incidentFormStore.isRequired.information.detail.placeholder),
                  title: this.$t(this.incidentFormStore.isRequired.information.detail.label),
                  name: this.incidentFormStore.isRequired.information.detail.name,
                  placeholder: this.$t(this.incidentFormStore.isRequired.information.detail.placeholder),
                  value: this.incidentFormStore?.result?.information?.detail,
                } as typeof FormTextareaDefaultComponent.props,
                onUpdateValue: (value: $text) => {
                  this.incidentFormStore.result.information.detail = value;
                },
              },
            },
          },
          checkbox: {
            informerType: {
              component: FormCheckBoxDefaultComponent,
              props: {
                isRightPlaceholder: false,
                isSpecial: true,
                isVisible: true,
                isError: (this.incidentFormStore.error[this.incidentFormStore.isRequired.informerType.name] || "").length > 0,
                isIcon: false,
                isDisable: Boolean(this.incidentFormStore?.result?.responsibleID === null || this.$route.params.id || this.loading()),
                isRequired: this.incidentFormStore.isRequired.informerType.is,
                textError: this.incidentFormStore.isRequired.informerType.placeholder,
                title: "",
                name: this.incidentFormStore.isRequired.informerType.name,
                placeholder: this.$t(this.incidentFormStore.isRequired.informerType.placeholder),
                value: this.incidentFormStore?.result?.informerType,
                classEnumName: "text-ttp-primary",
                items: [
                  {
                    label: this.$t("layout.anonymous"),
                    value: this.$enums.ENUM_INFORMER_TYPE.ANONYMOUS,
                  },
                ],
              } as typeof FormCheckBoxDefaultComponent.props,
              onUpdateValue: (values: any[]) => {
                this.settingInformer(values);
              },
            },
          },
          popup: {
            assigneeTextarea: {
              component: PopupAssignTextareaComponent,
              props: {
                isLoading: this.loading(),
                textTitle: this.showTextWithStatus(),
                componentName: this.assigneeFormComponents.form.textarea.detail,
                textPlaceholder: this.$t("popup.textarea.process_placeholder"),
                functionProp: (e: Event) => {
                  this.handleSubmitSaveForm(e);
                },
                functionCancelProp: () => {
                  this.incidentAssigneeFormStore.result.validate = !this.incidentAssigneeFormStore.result.validate;
                },
              } as typeof PopupAssignTextareaComponent.props,
            },
            cancelPopup: {
              component: PopupCancelComponent,
              props: {
                isOpen: this.incidentFormStore.isCancel,
                textTitle: this.$t("popup.cancel.cancel_title"),
                textDetail: this.$t("popup.cancel.cancel_detail"),
                functionProp: () => {
                  this.incidentFormStore.isCancel = false;
                  this.$router.push({ name: "IncidentView" });
                  this.reset();
                },
                functionCancelProp: async () => {
                  this.incidentFormStore.isCancel = false;
                },
              } as typeof PopupCancelComponent.props,
            },
            formSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.incidentFormStore.isSuccess,
                textTitle: this.$t("popup.success.title"),
                textDetail: this.$t("popup.success.detail"),
                functionProp: () => {
                  this.incidentFormStore.isSuccess = false;
                  this.$router.push({
                    name: "IncidentNoticeView",
                    params: { id: this.$route.params.id ? this.$route.params.id : this.incidentFormStore.id },
                  });
                  this.reset();
                },
                functionCancelProp: async () => {
                  this.incidentFormStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            formCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.incidentFormStore.isSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.error_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: async () => {
                  this.incidentFormStore.isSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
          },
          button: {
            btnResponsible: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: this.loading(),
                componentIcon: this.$outlineIcons.CheckCircleIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                title: this.$t("btn.btn_receive"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
              click: () => {
                this.incidentAssigneeFormStore.action = this.$enums.ENUM_MUTATION.RECEIVE;
                this.incidentAssigneeFormStore.updateResponsible(String(this.$route.params.id));

                this.socket.emit("update", this.incidentFormStore.result);
              },
            },
            btnEdit: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: (this.$route.params.id && this.incidentFormStore?.result?.responsibleID === null) || this.loading(),
                componentIcon: this.$outlineIcons.PencilSquareIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                title: "",
                classEnumName: this.$enums.ENUM_COLOR.NONE,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: () => {
                this.incidentFormStore.isMap = !this.incidentFormStore.isMap;
              },
            },
            btnSave: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: (this.$route.params.id && this.incidentFormStore?.result?.responsibleID === null) || this.loading(),
                htmlIcon: "",
                isSubmit: false,
                title: this.$t("btn.btn_save"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: (e: Event) => {
                this.handleSubmitValidateForm(e);
              },
            },
            btnCancel: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                title: this.$t("btn.btn_cancel"),
                classEnumName: this.$enums.ENUM_COLOR.DARK,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
              click: () => {
                this.incidentFormStore.action = this.$enums.ENUM_MUTATION.CANCEL;
                this.incidentFormStore.isCancel = !this.incidentFormStore.isCancel;
              },
            },
            btnReject: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: !this.$route.params.id || this.incidentFormStore?.result?.responsibleID === null || this.loading(),
                htmlIcon: "",
                title: this.$t("btn.btn_reject"),
                classEnumName: this.$enums.ENUM_COLOR.RED,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
              click: (e: Event) => {
                this.incidentFormStore.action = this.$enums.ENUM_MUTATION.REJECT;
                this.incidentFormStore.result.status = this.$enums.ENUM_INCIDENT_STATUS.REJECTED;
                this.incidentAssigneeFormStore.result.status = this.$enums.ENUM_INCIDENT_STATUS.REJECTED;
                this.handleSubmitValidateForm(e);
              },
            },
            btnUploadImage: {
              click: () => {
                (document?.getElementById("file") as HTMLButtonElement).click();
              },
            },
          },
          badge: {
            status: {
              component: BadgeDefaultComponent,
              props: {
                htmlIcon: "",
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: false,
                size: this.$enums.ENUM_SIZE.NORMAL,
              } as typeof BadgeDefaultComponent.props,
            },
          },
        },
      };
    },
    ableToSetResponsibleArea() {
      return this.incidentFormStore?.result?.status === this.$enums.ENUM_INCIDENT_STATUS.WAITING;
    },
    ableToSetExternalDepartmentID() {
      return this.incidentFormStore?.result?.status === this.$enums.ENUM_INCIDENT_STATUS.FORWARDED;
    },
  },
  methods: {
    showTextWithStatus() {
      switch (this.incidentFormStore.result.status) {
        case this.$enums.ENUM_INCIDENT_STATUS.REJECTED:
          return this.$t("popup.textarea.reject_title");
        case this.$enums.ENUM_INCIDENT_STATUS.CLOSED:
          return this.$t("popup.textarea.close_title");
        default:
          return this.$t("popup.textarea.process_title");
      }
    },
    isDisableStatus_IncidentFormStore() {
      return this.$inArray(this.authStore.displayRole, []);
    },
    isDisableStatus_IncidentFormStore_StatusField() {
      return this.$inArray(this.authStore.displayRole, PERMISSION_INCIDENT_CREATE);
    },
    async findInformer(e: any, type: "name" | "contactNumber" | "email" | "identificationNumber") {
      if (e.target.value) {
        this.masterDataStore.loading = true;

        await this.masterDataStore.listInformer({
          [type]: e.target.value,
        });

        this.masterDataStore.loading = false;
        this.incidentFormStore.isDialogName = type === "name";
        this.incidentFormStore.isDialogEmail = type === "email";
        this.incidentFormStore.isDialogContactNumber = type === "contactNumber";
        this.incidentFormStore.isDialogIdentificationNumber = type === "identificationNumber";
      }
    },
    assignValueInformer(data: any) {
      this.incidentFormStore.result.guest.name = data.name;
      this.incidentFormStore.result.guest.contactNumber = data.contactNumber;
      this.incidentFormStore.result.guest.email = data.email;
      this.incidentFormStore.result.touristID = data.ref == "TOURIST" ? data.refID : null;
      this.incidentFormStore.result.informerType = data.ref ? data.ref : this.$enums.ENUM_INFORMER_TYPE.ANONYMOUS;
      this.incidentFormStore.result.guestID = data.ref == "GUEST" ? data.refID : null;
      this.incidentFormStore.result.guest.gender = data.gender;
      this.incidentFormStore.result.guest.nationality = data.nationality;
      this.incidentFormStore.result.guest.country = data.country;
      this.incidentFormStore.result.guest.identificationNumber = data.identificationNumber;
      this.incidentFormStore.isDialogName = false;
      this.incidentFormStore.isDialogEmail = false;
      this.incidentFormStore.isDialogContactNumber = false;
      this.incidentFormStore.isDialogIdentificationNumber = false;
    },
    settingInformer(values: any[]) {
      if (values.length) this.incidentFormStore.result.informerType = this.$enums.ENUM_INFORMER_TYPE.ANONYMOUS;
      else this.incidentFormStore.result.informerType = this.$enums.ENUM_INFORMER_TYPE.GUEST;

      this.incidentFormStore.result.guest.name = null;
      this.incidentFormStore.result.guest.contactNumber = null;
      this.incidentFormStore.result.guest.email = null;
      this.incidentFormStore.result.guest.gender = null;
      this.incidentFormStore.result.guest.nationality = null;
      this.incidentFormStore.result.guest.country = null;
      this.incidentFormStore.result.guest.identificationNumber = null;
      this.incidentFormStore.result.guestID = null;
      this.incidentFormStore.result.touristID = null;
    },
    async initializeMap() {
      this.incidentFormStore.isMapGenerate = true;
      const google = await this.$asyncGoogleMapsLoader();
      const { Map } = (await google.maps.importLibrary("maps")) as any;
      const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as any;
      const thailandCoordinates = { lat: 13.736717, lng: 100.523186 };

      const map = new Map(document.getElementById("map") as HTMLElement, {
        center: this.$route.params.id
          ? {
              lat: Number(this.incidentFormStore.result.information.latitude),
              lng: Number(this.incidentFormStore.result.information.longitude),
            }
          : thailandCoordinates,
        zoom: 14,
        mapId: "4504f8b37365c3d0",
        mapTypeId: "roadmap",
      });

      const draggableMarker = await new AdvancedMarkerElement({
        map,
        position: this.$route.params.id
          ? {
              lat: Number(this.incidentFormStore.result.information.latitude),
              lng: Number(this.incidentFormStore.result.information.longitude),
            }
          : thailandCoordinates,
        gmpDraggable: true,
        title: "This marker is draggable.",
      });
      await draggableMarker.addListener("dragend", async (e: any) => {
        const position = draggableMarker.position as any;
        this.incidentFormStore.result.information.latitude = position.lat;
        this.incidentFormStore.result.information.longitude = position.lng;

        const newPosition = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        } as any;

        const geocoder = await new google.maps.Geocoder();
        const result = await geocoder.geocode(
          { location: newPosition },
          function (results: any, status: any) {
            if (status === "OK") {
              if (results[0]) {
                const address = results[0].formatted_address;

                (document.getElementById("information.location") as HTMLInputElement).value = address;
              }
            } else {
              console.error("Geocoder failed due to: " + status);
            }
          }.bind(this)
        );
        this.incidentFormStore.result.information.location = result.results[0].formatted_address;
      });

      if (!this.$route.params.id && navigator.geolocation) {
        const position = await Geolocation.getCurrentPosition();
        var selfCoordinates = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.incidentFormStore.result.information.latitude = position.coords.latitude;
        this.incidentFormStore.result.information.longitude = position.coords.longitude;

        await map.setCenter(selfCoordinates);
        draggableMarker.position = selfCoordinates;

        const geocoder = await new google.maps.Geocoder();
        const result = await geocoder.geocode(
          { location: selfCoordinates },
          function (results: any, status: any) {
            if (status === "OK") {
              if (results[0]) {
                const address = results[0].formatted_address;

                (document.getElementById("information.location") as HTMLInputElement).value = address;
              }
            } else {
              console.error("Geocoder failed due to: " + status);
            }
          }.bind(this)
        );
        this.incidentFormStore.result.information.location = result.results[0].formatted_address;
      }

      const input = document.getElementById("information.location") as HTMLInputElement;
      const searchBox = await new google.maps.places.SearchBox(input);
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

      await map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds() as any);
      });

      let markers: any[] = [];
      await searchBox.addListener("places_changed", () => {
        if (this.incidentFormStore.isMap) {
          const places = searchBox.getPlaces();

          if (places.length == 0) return;
          markers.forEach((marker) => {
            marker.setMap(null);
          });
          markers = [];
          const bounds = new google.maps.LatLngBounds();

          places.forEach((place: any) => {
            if (!place.geometry || !place.geometry.location) return;

            draggableMarker.position = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };

            this.incidentFormStore.result.information.latitude = place.geometry.location.lat();
            this.incidentFormStore.result.information.longitude = place.geometry.location.lng();

            this.incidentFormStore.result.information.location = `${place.name}, ${place.formatted_address}`;

            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          map.fitBounds(bounds);
        }
      });
    },
    async handleSubmitValidateForm(e: Event) {
      const incidentResult = await this.incidentFormStore.onSubmit(e);

      if (!this.$route.params.id) {
        const assigneeResult = await this.incidentAssigneeFormStore.onSubmit(e);
        this.incidentAssigneeFormStore.result.validate = incidentResult && assigneeResult;
      } else {
        if (this.incidentFormStore.result.status == this.$enums.ENUM_INCIDENT_STATUS.WAITING || this.incidentFormStore.result.status == this.$enums.ENUM_INCIDENT_STATUS.FORWARDED) {
          const assigneeResult = await this.incidentAssigneeFormStore.onSubmit(e);
          this.incidentAssigneeFormStore.result.validate = incidentResult && assigneeResult;
        } else {
          this.incidentAssigneeFormStore.result.validate = incidentResult == true;
        }
      }
    },
    async handleSubmitSaveForm(e: Event) {
      const validate = await this.incidentAssigneeFormStore.onSubmit(e);
      this.incidentFormStore.sendResult.images = this.incidentFormStore.sendResult.images ? this.incidentFormStore.sendResult.images.map(({ id }: any) => id) : [];

      if (validate) {
        let dataIncident: typeGetIncident;
        if (!this.$route.params.id) {
          dataIncident = await this.incidentFormStore.create(this.incidentFormStore.sendResult);
          this.incidentFormStore.id = String(dataIncident.id);
        } else {
          dataIncident = await this.incidentFormStore.update(this.incidentFormStore.result.id, this.incidentFormStore.sendResult);
        }

        if (!this.$route.params.id || this.$inArray(this.incidentFormStore.result.status, [this.$enums.ENUM_INCIDENT_STATUS.ACCEPTED, this.$enums.ENUM_INCIDENT_STATUS.IN_REVIEW, this.$enums.ENUM_INCIDENT_STATUS.WAITING, this.$enums.ENUM_INCIDENT_STATUS.FORWARDED])) {
          this.incidentAssigneeFormStore.sendResult = deleteKeysFromObject(this.incidentAssigneeFormStore.sendResult, ["validate", "routeName"]);
          await this.incidentAssigneeFormStore.updateProgress(String(dataIncident.id), this.incidentAssigneeFormStore.sendResult);
        }

        this.socket.emit("update", dataIncident);
      }
    },
    async settingResults(result: typeGetIncident) {
      // Master Data
      this.masterDataStore.loadingDistrict = true;
      this.masterDataStore.loadingSubDistrict = true;
      this.masterDataStore.loadingTopicCode = true;
      this.masterDataStore.loadingStations = true;
      this.masterDataStore.districts = [];
      this.masterDataStore.subDistricts = [];
      this.masterDataStore.incidentTopics = [];
      this.masterDataStore.stations = [];

      if (!result) {
        return;
      }

      this.masterDataStore.provinceID = result.information?.provinceID;
      this.masterDataStore.districtID = result.information?.districtID;
      this.masterDataStore.categoryCode = result.information?.categoryCode;

      this.masterDataStore.listIncidentFriendlyCategory();

      if (result.information?.provinceID) {
        this.masterDataStore.listExternalDepartments(this.masterDataStore.provinceID || 0);

        await this.masterDataStore.listDistrict(this.masterDataStore.provinceID || 0).then(() => {
          this.masterDataStore.loadingDistrict = false;
        });
      }

      if (result.information?.districtID) {
        await this.masterDataStore.listSubDistrict(this.masterDataStore.provinceID || 0, this.masterDataStore.districtID || 0).then(() => {
          this.masterDataStore.loadingSubDistrict = false;
        });
      }

      if (result.information?.categoryCode) {
        await this.masterDataStore.listIncidentTopic(this.masterDataStore.categoryCode || "").then(() => {
          this.masterDataStore.loadingTopicCode = false;
        });
      }

      if (result.information?.provinceID || result.information?.districtID) {
        await this.masterDataStore.listStations(result.information?.provinceID || 0, result.information?.districtID || 0).then(() => {
          this.masterDataStore.loadingStations = false;
        });
      }

      // Result
      if (result.informerType == this.$enums.ENUM_INFORMER_TYPE.TOURIST) {
        result.guest = result.tourist;
        result.guestID = result.touristID;
        result.touristID = null;
      }

      result = intersectJSON(result, this.incidentFormStore.result);
      result = assignValues(result, ["images", "information.provinceID", "information.districtID", "information.subDistrictID", "guest", "guest.name", "guest.nationality", "guest.country", "guest.contactNumber", "guest.email", "guest.gender", "guest.identificationNumber", "guest.emergencyContact", "guest.emergencyContact.name", "guest.emergencyContact.contactNumber", "guest.emergencyContact.identificationNumber", "responsibleAreaCode"], "");
      result.guest.identificationType = this.$enums.ENUM_IDENTIFICATION_TYPE.PASSPORT;
      result.guest.emergencyContact.identificationType = this.$enums.ENUM_IDENTIFICATION_TYPE.PASSPORT;

      if (result.status && [this.$enums.ENUM_INCIDENT_STATUS.ACCEPTED].includes(result.status as ENUM_INCIDENT_STATUS)) {
        this.incidentFormStore.result.status = this.$enums.ENUM_INCIDENT_STATUS.WAITING;
        this.incidentAssigneeFormStore.result.status = this.$enums.ENUM_INCIDENT_STATUS.WAITING;
      }

      if (result.images?.legnth) {
        const ff = new fetchFile();
        result.images = await result.images.map((id: any) => ff.Get(id));
      }
      this.incidentFormStore.result = result;

      // Get Image
      if (this?.incidentFormStore?.result?.images?.length) {
        this.incidentFormStore.result.images = await Promise.all(await this.incidentFormStore.fetchFile.Get(this?.incidentFormStore?.result?.images));
      }

      // Get Profile Image
      if (this?.incidentFormStore?.result?.tourist?.image) {
        this.incidentFormStore.result.tourist.image = await Promise.all(await this.incidentFormStore.fetchFile.Get([this?.incidentFormStore?.result?.tourist?.image]));
      }

      // Latitude & Longitude Not Found
      if (!this.incidentFormStore.result.information.latitude) {
        this.incidentFormStore.result.information.latitude = 13.736717;
        this.incidentFormStore.result.information.longitude = 100.523186;
      }

      this.$configLightbox();
    },
    async fetch() {
      if (this.$route.params.id) {
        const result = await this.incidentFormStore.fetchIncident.Get(this.$route.params.id as string);

        this.incidentAssigneeFormStore.result.status = result.status;
        if (this.incidentAssigneeFormStore.result.status == "ACCEPTED") this.incidentFormStore.action = this.$enums.ENUM_MUTATION.ACCEPT;

        //  Check Process
        this.incidentFormStore.metadata = this.$extractMetadata(result?.progress || []);

        await this.settingResults(result);
      } else {
        this.incidentFormStore.result.information.latitude = 13.736717;
        this.incidentFormStore.result.information.longitude = 100.523186;
      }

      this.incidentFormStore.loading = false;
    },
    color() {
      return `text-[${this.$getColorStatus(new Date(this.incidentFormStore.metadata.createdAt), new Date(this.incidentFormStore.metadata.eta), new Date(this.incidentFormStore.metadata.arrived), "TH").color}]`;
    },
    loading() {
      return this.incidentFormStore.loading || this.incidentAssigneeFormStore.loading;
    },
    async reset() {
      await this.incidentFormStore.$reset();
      await this.incidentAssigneeFormStore.$reset();
      await this.masterDataStore.resetItems();
    },
    async setting() {
      await this.reset();
      await this.fetch();
      this.incidentAssigneeFormStore.isRequired.assigneeID.is = false;
      this.incidentAssigneeFormStore.result.routeName = "IncidentManageView";

      this.$configLightbox();
    },
    async uploadImage(e: any) {
      let listFiles = e.target.files;
      if (!listFiles?.length) {
        return;
      }

      const files: File[] = [];
      for (const f of listFiles) {
        files.push(f);
      }

      const f = new fetchFile();
      const response = await f.Upload(files);

      if (!this.incidentFormStore.result.images) {
        this.incidentFormStore.result.images = [];
      }

      this.incidentFormStore.result.images.push(...response);
      e.target.value = "";
    },
  },
  async mounted() {
    this.socket.connect();
    await this.setting();
  },
  beforeUnmount() {
    this.socket.disconnect();
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const incidentFormStore = useIncidentFormStore();
    const componentIncidentFormStore = useComponentIncidentFormStore();
    const incidentAssigneeFormStore = useIncidentAssigneeFormStore();
    const masterDataStore = useMasterDataStore();
    const sharedStore = useSharedStore();
    const authStore = useAuthStore();

    const socket = io(import.meta.env.VITE_INCIDENT_SOCKET_URL, {
      path: "/incident",
      secure: true,
      transports: ["websocket", "polling"],
      forceNew: true,
      upgrade: true,
    });

    socket.on('connect_error', (err) => {
      console.error('socket connect error', err);
    });

    return {
      socket,
      incidentFormStore,
      componentIncidentFormStore,
      masterDataStore,
      incidentAssigneeFormStore,
      sharedStore,
      authStore,
    };
  },
});
</script>

<template>
  <div class="ttp-layout overflow-hidden">
    <div class="ttp-page-header-1 pb-0">
      <div class="flex w-full ttp-between">
        <h2 class="w-full text-gray-800 ttp-text-base font-semibold text-left">
          {{ $t("page.IncidentManageView.title") }}
        </h2>
        <div v-if="$route.params.id && incidentFormStore?.result?.responsibleID === null">
          <component :classTagName="'w-36'" v-bind="defaultFormComponents.form.button.btnResponsible.props" :is="defaultFormComponents.form.button.btnResponsible.component" @click="defaultFormComponents.form.button.btnResponsible.click" />
        </div>
        <div v-else-if="$route.params.id" class="flex w-full gap-2 items-center justify-end">
          <div>
            <div class="text-right text-gray-800 ttp-text-xs font-normal">
              {{ $convertString(incidentFormStore?.result?.responsible?.content ? incidentFormStore?.result?.responsible?.content[0]?.fullName : "") }}
            </div>
            <div class="text-right text-gray-500 ttp-text-xs font-medium leading-[14px]">
              {{ $t("form.recipient.label") }}
            </div>
          </div>
          <div class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
            <component :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-03" />
          </div>
        </div>
      </div>
    </div>
    <form @submit="incidentFormStore.onSubmit">
      <div v-if="$route.params.id" class="flex w-full justify-center items-center p-4">
        <div class="flex w-full justify-center items-center gap-4">
          <component :is="$outlineIcons.DocumentTextIcon" class="flex-none inline-block ttp-icon-inside-box-02 text-gray-600" />

          <div class="flex flex-col w-full">
            <div>
              <div class="text-gray-600 ttp-text-base font-semibold leading-tight mb-1 text-left">
                <span class="font-medium"><component :is="$solidIcons.IdentificationIcon" class="flex-none inline-block ttp-icon-inside-box-03" /> {{ $t("page.IncidentManageView.document_number") }}</span>
                <span class="ml-1"> {{ $convertString(incidentFormStore?.result?.incidentNumber) }}</span>
              </div>
            </div>
            <div class="flex justify-start items-start md:flex-row gap-2 w-full text-gray-600 ttp-text-xs text-left">
              <component :is="$solidIcons.ClockIcon" class="flex-none inline-block ttp-icon-inside-box-03" /><span class="font-medium">{{ $t("page.IncidentManageView.receive_date") }}</span>
              <div class="leading-0 flex justify-center items-center">
                <span class="ttp-text-xs font-medium ml-1">{{ $dayjs(incidentFormStore?.result?.createdAt).format("DD/MM/YYYY HH:mm") }}</span>
              </div>
            </div>
            <div class="flex justify-start items-start md:flex-row gap-2 w-full text-gray-600 ttp-text-xs text-left">
              <component :is="$solidIcons.ClockIcon" class="flex-none inline-block ttp-icon-inside-box-03" /><span class="font-medium">{{ $t("form.updated_date.label") }}</span>
              <div class="leading-0 flex justify-center items-center">
                <span class="ttp-text-xs font-medium ml-1">{{ $dayjs(incidentFormStore?.result?.updatedAt).format("DD/MM/YYYY HH:mm") }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-2 w-32 text-right">
          <div class="w-full text-right">
            <div class="w-auto ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.status.label") }} :</div>
          </div>
          <div class="w-full text-gray-500 ttp-text-xs">
            <component :is="defaultFormComponents.form.badge.status.component" v-bind="defaultFormComponents.form.badge.status.props" :title="$formattedStatus($classStatus(incidentFormStore?.result?.status))" :classEnumName="$classStatus(incidentFormStore?.result?.status)" />
          </div>
        </div>
      </div>
      <div class="w-full p-4 border-t-2">
        <div class="flex gap-2">
          <div class="mt-4">
            <div class="swipe-photo inline-flex ttp-icon-box-04 items-center justify-center rounded-lg bg-gray-100 text-gray-300 overflow-hidden">
              <component v-if="!incidentFormStore?.result?.tourist?.image" :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-01" />
              <a v-else :href="incidentFormStore?.result?.tourist?.image[0]?.url" target="_blank"><img :src="incidentFormStore?.result?.tourist?.image[0]?.url" class="object-cover object-center ttp-icon-box-04" /></a>
            </div>
          </div>
          <div class="flex flex-col gap-2 w-full">
            <div class="grid grid-cols-12 relative gap-2 p-4">
              <div class="col-start-1 col-end-13 ttp-text-xs font-medium text-gray-900 dark:text-gray-50">
                ข้อมูลนักท่องเที่ยว
              </div>
              <div class="col-start-1 col-end-13 md:col-start-1 md:col-end-7 relative">
                <component :classTagName="'w-full relative z-[13]'" :is="defaultFormComponents.form.input.guest.name.component" v-bind="defaultFormComponents.form.input.guest.name.props" @updateValue="defaultFormComponents.form.input.guest.name.onUpdateValue" @click="defaultFormComponents.form.input.guest.name.click" @input="defaultFormComponents.form.input.guest.name.input" @blur="defaultFormComponents.form.input.guest.name.blur" />
                <div v-if="incidentFormStore.isDialogName && masterDataStore.informers.length" class="flex flex-col w-full max-h-[160px] overflow-auto shadow-md rounded-md absolute top-16 z-[13] bg-white">
                  <div v-if="masterDataStore.loading" class="flex justify-center items-center w-full gap-2 p-2 cursor-pointer hover:bg-ttp-disabled">
                    <LoadingFormComponent />
                    <div class="py-1">{{ $t("layout.loading") }}</div>
                  </div>
                  <div
                    v-else
                    v-for="(data, index) in masterDataStore.informers"
                    :key="index"
                    class="flex w-full gap-2 p-2 cursor-pointer hover:bg-ttp-disabled"
                    @click="
                      () => {
                        assignValueInformer(data);
                      }
                    "
                  >
                    <div class="w-[50px]">
                      <div class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                        <component :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-03" />
                      </div>
                    </div>
                    <div class="flex flex-col gap-0.5 w-full">
                      <div class="w-full block ttp-text-xs font-normal text-gray-900 dark:text-gray-50">
                        {{ $convertString(data.name) }}
                      </div>
                      <div class="w-full block ttp-text-xs font-normal text-gray-500 dark:text-gray-50">
                        {{ $convertString(data.contactNumber) }} |
                        {{ $convertString(data.email) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-start-1 col-end-13 md:col-start-7 md:col-end-13 relative">
                <component :classTagName="'w-full relative z-[13]'" :is="defaultFormComponents.form.input.guest.contactNumber.component" v-bind="defaultFormComponents.form.input.guest.contactNumber.props" @updateValue="defaultFormComponents.form.input.guest.contactNumber.onUpdateValue" @click="defaultFormComponents.form.input.guest.contactNumber.click" @input="defaultFormComponents.form.input.guest.contactNumber.input" @blur="defaultFormComponents.form.input.guest.contactNumber.blur" />
                <div v-if="incidentFormStore.isDialogContactNumber && masterDataStore.informers.length" class="flex flex-col w-full max-h-[160px] overflow-auto shadow-md rounded-md absolute top-16 z-[13] bg-white">
                  <div v-if="masterDataStore.loading" class="flex justify-center items-center w-full gap-2 p-2 cursor-pointer hover:bg-ttp-disabled">
                    <LoadingFormComponent />
                    <div class="py-1">{{ $t("layout.loading") }}</div>
                  </div>
                  <div
                    v-else
                    v-for="(data, index) in masterDataStore.informers"
                    :key="index"
                    class="flex w-full gap-2 p-2 cursor-pointer hover:bg-ttp-disabled"
                    @click="
                      () => {
                        assignValueInformer(data);
                      }
                    "
                  >
                    <div class="w-[50px]">
                      <div class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                        <component :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-03" />
                      </div>
                    </div>
                    <div class="flex flex-col gap-0.5 w-full">
                      <div class="w-full block ttp-text-xs font-normal text-gray-900 dark:text-gray-50">
                        {{ $convertString(data.name) }}
                      </div>
                      <div class="w-full block ttp-text-xs font-normal text-gray-500 dark:text-gray-50">
                        {{ $convertString(data.contactNumber) }} |
                        {{ $convertString(data.email) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-start-1 col-end-13 md:col-start-1 md:col-end-7 relative">
                <component :classTagName="'w-full relative z-[11]'" :is="defaultFormComponents.form.input.guest.email.component" v-bind="defaultFormComponents.form.input.guest.email.props" @updateValue="defaultFormComponents.form.input.guest.email.onUpdateValue" @click="defaultFormComponents.form.input.guest.email.click" @input="defaultFormComponents.form.input.guest.email.input" @blur="defaultFormComponents.form.input.guest.email.blur" />
                <div v-if="incidentFormStore.isDialogEmail && masterDataStore.informers.length" class="flex flex-col w-full max-h-[160px] overflow-auto shadow-md rounded-md absolute top-16 z-[11] bg-white">
                  <div v-if="masterDataStore.loading" class="flex justify-center items-center w-full gap-2 p-2 cursor-pointer hover:bg-ttp-disabled">
                    <LoadingFormComponent />
                    <div class="py-1">{{ $t("layout.loading") }}</div>
                  </div>
                  <div
                    v-else
                    v-for="(data, index) in masterDataStore.informers"
                    :key="index"
                    class="flex w-full gap-2 p-2 cursor-pointer hover:bg-ttp-disabled"
                    @click="
                      () => {
                        assignValueInformer(data);
                      }
                    "
                  >
                    <div class="w-[50px]">
                      <div class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                        <component :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-03" />
                      </div>
                    </div>
                    <div class="flex flex-col gap-0.5 w-full">
                      <div class="w-full block ttp-text-xs font-normal text-gray-900 dark:text-gray-50">
                        {{ $convertString(data.name) }}
                      </div>
                      <div class="w-full block ttp-text-xs font-normal text-gray-500 dark:text-gray-50">
                        {{ $convertString(data.contactNumber) }} |
                        {{ $convertString(data.email) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-start-1 col-end-13 md:col-start-7 md:col-end-13 relative">
                <component :classTagName="'w-full relative z-[11]'" :is="defaultFormComponents.form.input.guest.identificationNumber.component" v-bind="defaultFormComponents.form.input.guest.identificationNumber.props" @updateValue="defaultFormComponents.form.input.guest.identificationNumber.onUpdateValue" @click="defaultFormComponents.form.input.guest.identificationNumber.click" @input="defaultFormComponents.form.input.guest.identificationNumber.input" @blur="defaultFormComponents.form.input.guest.identificationNumber.blur" />
                <div v-if="incidentFormStore.isDialogIdentificationNumber && masterDataStore.informers.length" class="flex flex-col w-full max-h-[160px] overflow-auto shadow-md rounded-md absolute top-16 z-[11] bg-white">
                  <div v-if="masterDataStore.loading" class="flex justify-center items-center w-full gap-2 p-2 cursor-pointer hover:bg-ttp-disabled">
                    <LoadingFormComponent />
                    <div class="py-1">{{ $t("layout.loading") }}</div>
                  </div>
                  <div
                    v-else
                    v-for="(data, index) in masterDataStore.informers"
                    :key="index"
                    class="flex w-full gap-2 p-2 cursor-pointer hover:bg-ttp-disabled"
                    @click="
                      () => {
                        assignValueInformer(data);
                      }
                    "
                  >
                    <div class="w-[50px]">
                      <div class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                        <component :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-03" />
                      </div>
                    </div>
                    <div class="flex flex-col gap-0.5 w-full">
                      <div class="w-full block ttp-text-xs font-normal text-gray-900 dark:text-gray-50">
                        {{ $convertString(data.name) }}
                      </div>
                      <div class="w-full block ttp-text-xs font-normal text-gray-500 dark:text-gray-50">
                        {{ $convertString(data.contactNumber) }} |
                        {{ $convertString(data.email) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-7'" :is="defaultFormComponents.form.select.guest.nationality.component" v-bind="defaultFormComponents.form.select.guest.nationality.props" @updateValue="defaultFormComponents.form.select.guest.nationality.onUpdateValue" />
              <component :classTagName="'col-start-1 col-end-13 md:col-start-7 md:col-end-13'" :is="defaultFormComponents.form.select.guest.country.component" v-bind="defaultFormComponents.form.select.guest.country.props" @updateValue="defaultFormComponents.form.select.guest.country.onUpdateValue" />
              <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-7'" :is="defaultFormComponents.form.select.guest.gender.component" v-bind="defaultFormComponents.form.select.guest.gender.props" @updateValue="defaultFormComponents.form.select.guest.gender.onUpdateValue" />
              <template v-if="incidentFormStore?.result?.channel !== 'APPLICATION'">
                <hr class="col-start-1 col-end-13 mt-2" />
                <div class="col-start-1 col-end-13 my-2 ttp-text-xs font-medium text-gray-900 dark:text-gray-50">
                  {{ $t("page.IncidentManageView.emergency_contact") }}
                </div>
                <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-7'" :is="defaultFormComponents.form.input.guest.emergencyContact.name.component" v-bind="defaultFormComponents.form.input.guest.emergencyContact.name.props" @updateValue="defaultFormComponents.form.input.guest.emergencyContact.name.onUpdateValue" />
                <component :classTagName="'col-start-1 col-end-13 md:col-start-7 md:col-end-13'" :is="defaultFormComponents.form.input.guest.emergencyContact.contactNumber.component" v-bind="defaultFormComponents.form.input.guest.emergencyContact.contactNumber.props" @updateValue="defaultFormComponents.form.input.guest.emergencyContact.contactNumber.onUpdateValue" />
              </template>
              <hr class="col-start-1 col-end-13 mt-2" />
              <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-2'" :is="defaultFormComponents.form.textarea.information.detail.component" v-bind="defaultFormComponents.form.textarea.information.detail.props" @updateValue="defaultFormComponents.form.textarea.information.detail.onUpdateValue" />
              <div class="ttp-text-xs font-normal text-gray-900 dark:text-gray-50 leading-tight col-start-1 col-end-13">
                <div class="mb-2 font-medium">{{ $t("form.image_name.label") }}</div>
                <div id="ttp-gallery" class="flex gap-2 mb-4">
                  <div class="swipe-photo flex gap-2 w-full">
                    <div v-if="$isArrayOfStringsOrObjects(incidentFormStore?.result?.images)" class="flex flex-col justify-center items-center w-full">
                      <div><LoadingFormComponent /></div>
                      <div class="ttp-text-xs">{{ $t("layout.loading") }}</div>
                    </div>
                    <a v-else-if="incidentFormStore?.result?.images?.length" v-for="(_item, index) in incidentFormStore?.result?.images" :key="index" :href="_item?.url ? _item?.url : '#'" target="_blank"><img class="object-cover object-center w-14 h-14 sm:w-36 sm:h-36 max-w-full rounded-lg float-right ml-2" :src="_item?.url" alt="" /></a>
                    <div v-else>-</div>
                  </div>
                </div>
                <div class="flex gap-2 mt-4">
                  <ButtonDefaultComponent :isSpecial="false" :isVisible="true" :isIcon="true" :componentIcon="$outlineIcons.PhotoIcon" :classIcon="'ttp-icon-inside-box-03 inline-block'" :isLoading="loading()" :isDisable="($route.params.id && incidentFormStore?.result?.responsibleID === null) || isDisableStatus_IncidentFormStore() || loading()" :title="$t('btn.btn_select')" :classEnumName="$enums.ENUM_COLOR.DEFAULT_1" :size="$enums.ENUM_SIZE.SMALL" @click="defaultFormComponents.form.button.btnUploadImage.click" />
                  <input id="file" type="file" accept="image/*" multiple hidden @change="uploadImage" />
                </div>
              </div>
              <div class="col-start-1 col-end-7 md:col-start-1 md:col-end-7 mt-2">
                <div class="block mb-1 ttp-text-xs font-medium text-gray-900 dark:text-gray-50">
                  {{ $t("form.notificationChannel.label") }}:
                  <span class="text-gray-500 ttp-text-xs">{{ incidentFormStore?.result?.channel == "1155" ? $t("role.CENTRAL_OFFICER") : $replaceAll($convertString(incidentFormStore?.result?.channel), "_", " ") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex w-full border-t-2">
          <div class="flex flex-col gap-2 w-full">
            <div class="grid grid-cols-12 gap-2 p-4">
              <component :classTagName="'col-start-1 col-end-8 md:col-start-1 md:col-end-8'" :is="defaultFormComponents.form.select.information.friendlyCategoryCode.component" v-bind="defaultFormComponents.form.select.information.friendlyCategoryCode.props" @updateValue="defaultFormComponents.form.select.information.friendlyCategoryCode.onUpdateValue" />
              <component :classTagName="'col-start-1 col-end-3 md:col-start-1 md:col-end-3'" :is="defaultFormComponents.form.input.information.incidentCode.component" v-bind="defaultFormComponents.form.input.information.incidentCode.props" @updateValue="defaultFormComponents.form.input.information.incidentCode.onUpdateValue" />
              <component :classTagName="'col-start-3 col-end-8 md:col-start-3 md:col-end-8'" :is="defaultFormComponents.form.select.information.categoryCode.component" v-bind="defaultFormComponents.form.select.information.categoryCode.props" @updateValue="defaultFormComponents.form.select.information.categoryCode.onUpdateValue" />
              <component :classTagName="'col-start-8 col-end-13 md:col-start-8 md:col-end-13'" :is="defaultFormComponents.form.select.information.topicCode.component" v-bind="defaultFormComponents.form.select.information.topicCode.props" @updateValue="defaultFormComponents.form.select.information.topicCode.onUpdateValue" />
              <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-5'" :is="defaultFormComponents.form.select.information.provinceID.component" v-bind="defaultFormComponents.form.select.information.provinceID.props" @updateValue="defaultFormComponents.form.select.information.provinceID.onUpdateValue" />
              <component :classTagName="'col-start-1 col-end-13 md:col-start-5 md:col-end-9'" :is="defaultFormComponents.form.select.information.districtID.component" v-bind="defaultFormComponents.form.select.information.districtID.props" @updateValue="defaultFormComponents.form.select.information.districtID.onUpdateValue" />
              <component :classTagName="'col-start-1 col-end-13 md:col-start-9 md:col-end-13'" :is="defaultFormComponents.form.select.information.subDistrictID.component" v-bind="defaultFormComponents.form.select.information.subDistrictID.props" @updateValue="defaultFormComponents.form.select.information.subDistrictID.onUpdateValue" />
              <component :classTagName="'col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-2'" :is="defaultFormComponents.form.select.information.priority.component" v-bind="defaultFormComponents.form.select.information.priority.props" @updateValue="defaultFormComponents.form.select.information.priority.onUpdateValue" />
            </div>
          </div>
        </div>
        <div class="flex w-full">
          <div class="w-full p-4">
            <div class="flex ttp-text-xs font-medium text-gray-900 dark:text-gray-50 leading-tight">
              <button
                type="button"
                @click="
                  () => {
                    incidentFormStore.isMap = !incidentFormStore.isMap;
                    if (incidentFormStore.isMap && !incidentFormStore.isMapGenerate) {
                      initializeMap();
                    }
                  }
                "
                class="flex w-full items-center justify-center"
              >
                <div class="flex w-full ttp-text-xs gap-2">{{ $t("form.pin_location.label") }} <component :is="incidentFormStore.isMap ? $solidIcons.ChevronDownIcon : $solidIcons.ChevronUpIcon" class="inline-block ttp-icon-inside-box-03 opacity-40" /></div>
              </button>
              <!-- Google Map Link -->
              <div v-if="!$inArray(incidentFormStore?.result?.information?.latitude, [13.736717, null])" class="w-full text-right ttp-text-xs">
                <a :href="`https://maps.google.com/?q=${incidentFormStore?.result?.information?.latitude},${incidentFormStore?.result?.information?.longitude}`" target="_blank"
                  ><div class="inline-flex rounded bg-blue-200 px-2 py-1 ttp-text-xs font-semibold leading-4 text-blue-700">{{ $t("btn.btn_google_map") }}</div></a
                >
              </div>
            </div>

            <div :class="[{ 'flex w-full h-60 mt-2 relative bg-white border': true, hidden: !incidentFormStore.isMap }]">
              <div v-if="!incidentFormStore.isMap" :class="{ 'absolute flex w-full h-full z-10 items-center justify-center': true, 'bg-black opacity-80': $route.params.id && incidentFormStore.result.information.latitude == 13.736717 }">
                <div v-if="$route.params.id && incidentFormStore.result.information.latitude == 13.736717" class="text-white">ไม่พบพิกัดสถานที่</div>
              </div>
              <div class="flex w-full h-full">
                <component :classTagName="'relative col-start-1 col-end-13'" :classInputName="''" :is="defaultFormComponents.form.input.information.location.component" v-bind="defaultFormComponents.form.input.information.location.props" @updateValue="defaultFormComponents.form.input.information.location.onUpdateValue" />
                <div id="map"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex w-full">
          <div class="flex flex-col gap-2 w-full">
            <div class="grid grid-cols-12 gap-2 p-4">
              <component v-bind="assigneeFormComponents.form.radio.status.props" :is="assigneeFormComponents.form.radio.status.component" classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" @updateValue="assigneeFormComponents.form.radio.status.onUpdateValue" />
              <template v-if="ableToSetResponsibleArea">
                <component v-bind="defaultFormComponents.form.select.responsibleAreaCode.props" :is="defaultFormComponents.form.select.responsibleAreaCode.component" classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" @updateValue="defaultFormComponents.form.select.responsibleAreaCode.onUpdateValue" />
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row gap-2 justify-between p-4 border-t-2">
        <div class="flex gap-2">
          <component :is="defaultFormComponents.form.button.btnSave.component" v-bind="defaultFormComponents.form.button.btnSave.props" @click="defaultFormComponents.form.button.btnSave.click" />
          <component :is="defaultFormComponents.form.button.btnCancel.component" v-bind="defaultFormComponents.form.button.btnCancel.props" @click="defaultFormComponents.form.button.btnCancel.click" />
        </div>
        <div>
          <component v-if="$route.params.id" :is="defaultFormComponents.form.button.btnReject.component" v-bind="defaultFormComponents.form.button.btnReject.props" @click="defaultFormComponents.form.button.btnReject.click" />
        </div>
      </div>
    </form>
  </div>

  <button v-if="!componentIncidentFormStore.isChat" @click="componentIncidentFormStore.isChat = !componentIncidentFormStore.isChat" class="fixed flex gap-2 ttp-text-xs bottom-[0px] right-[10px] bg-ttp-chat-right text-white p-2 items-center justify-center rounded-tl-xl rounded-tr-xl">
    <div>
      <div class="inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" class="inline-block ttp-icon-inside-box-04"><path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clip-rule="evenodd"></path></svg>
      </div>
    </div>
    <div>{{ $convertString(incidentFormStore?.result?.guest?.name) }} ({{ $t("page.IncidentManageView.document_number") }}: {{ $convertString(incidentFormStore?.result?.incidentNumber) }})</div>
    <div><component :is="componentIncidentFormStore.isChat ? $solidIcons.ChevronDownIcon : $solidIcons.ChevronUpIcon" class="inline-block ttp-icon-inside-box-03" /></div>
  </button>
  <div v-else class="fixed right-0 bottom-0 w-full lg:w-[460px] z-[100]">
    <ChatDialogComponent />
  </div>

  <!-- Assignee Popup -->
  <component :isOpen="incidentAssigneeFormStore.result.validate" :is="defaultFormComponents.form.popup.assigneeTextarea.component" v-bind="defaultFormComponents.form.popup.assigneeTextarea.props" />

  <!-- Cancel Popup -->
  <component :is="defaultFormComponents.form.popup.cancelPopup.component" v-bind="defaultFormComponents.form.popup.cancelPopup.props" />

  <!-- Assignee Success & Caution -->
  <component v-if="incidentFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS || incidentAssigneeFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="assigneeFormComponents.form.popup.assigneeSuccess.component" v-bind="assigneeFormComponents.form.popup.assigneeSuccess.props" />
  <component v-else-if="incidentFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR || incidentAssigneeFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR" :is="assigneeFormComponents.form.popup.assigneeCaution.component" v-bind="assigneeFormComponents.form.popup.assigneeCaution.props" />

  <!-- Responsible -->
  <component v-if="incidentAssigneeFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="assigneeFormComponents.form.popup.reponsibleSuccess.component" v-bind="assigneeFormComponents.form.popup.reponsibleSuccess.props" />
  <component v-else-if="incidentAssigneeFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR" :is="assigneeFormComponents.form.popup.reponsibleCaution.component" v-bind="assigneeFormComponents.form.popup.reponsibleCaution.props" />

  <!-- Craimal -->
  <component v-if="incidentFormStore.isBackgroundCheck" :is="defaultFormComponents.alert.backgroundCheck.component" v-bind="defaultFormComponents.alert.backgroundCheck.props" />
</template>
