export default {
  layout: {
    appName: "観光警察",
    menu: {
      AlertView: `通知管理`,
      AlertManageView: `通知`,
      PreviewView: `プレビュー`,
      HomeView: `ホーム`,
      LogoutView: `ログアウト`,
      NotFoundView: `ページが見つかりません`,
      MaintenanceView: `メンテナンス`,
      LoginView: `ログイン`,
      ForgotPasswordView: `パスワードを変更する`,
      AllIncidentView: `すべてのインシデント`,
      IncidentView: `インシデント管理`,
      NewIncidentView: `新しいインシデント`,
      AcceptedIncidentView: `受け入れたインシデント`,
      WaitingIncidentView: `保留中のインシデント`,
      CurrentIncidentView: `進行中のインシデント`,
      ReportingIncidentView: `報告されたインシデント`,
      ClosingIncidentView: `終了中のインシデント`,
      ClosedIncidentView: `終了したインシデント`,
      RejectedIncidentView: `拒否されたインシデント`,
      ConferenceView: `NT カンファレンス`,
      OverviewView: `概要`,
      DashboardView: `ダッシュボード`,
      DashboardIncidentTypeView: `インシデントタイプ`,
      ProfileView: `マイアカウント`,
      SettingMenuView: `マイアカウント`,
      ProfileEditView: `ユーザープロフィール`,
      PasswordSettingView: `パスワード設定`,
      UserView: `ユーザー管理`,
      UserManageView: `ユーザープロフィール`,
      TouristView: `観光客管理`,
      TouristManageView: `観光客プロファイル`,
      ChatView: `チャット`,
      IncidentManageView: `インシデント管理`,
      IncidentNoticeView: `インシデント通知書`,
      IncidentReportView: `報告書`,
      HistoryView: `履歴`,
      HistoryNoticeView: `インシデント通知書`,
      HistoryReportView: `オンラインシステムに登録されたケースの初期報告書`,
      ReportIncidentView: `報告書`,
      VoiceCallView: `音声通話`,
      OverviewReportView: `レポート`,
      NewsView: "ニュース管理",
      NewsManageView: "ニュース",
      NewsInternalView: "社内ニュース",
      PopularPlaceView: "人気の場所",
    },
    menu_head: {
      PreviewTitle: "プレビュー",
      AccountTitle: "アカウント",
      UserTitle: "メンバー",
      TouristTitle: "観光客",
      IncidentTitle: "事故報告書",
      SupportTitle: "サポートプログラム",
      ProfileTitle: "プロフィール",
      HistoryTitle: "事故報告書",
      DashboardTitle: "ダッシュボード",
      NewsTitle: "Announcement",
    },
    menu_bottom: {
      home_title: "ホームページ",
      report_title: "報告",
      history_title: "歴史",
      profile_title: "プロフィール",
    },
    by: "搭載",
    create: "作成する",
    update: "編集",
    edit_address: "住所の編集",
    edit_address_placeholder: "住所を入力してください",
    please_input: "入ってください",
    not_found: "見つかりません",
    not_found_image: "画像が見つかりません",
    loading: "読み込み中",
    all: "全て",
    case: "場合",
    non: "指定なし",
    non_image: "いいえ、したくありません",
    non_location: "位置情報を共有したくない",
    select_picture: "イメージを選んでください",
    result: "結果",
    anonymous: "匿名",
    gender: {
      male: "男",
      female: "女性",
    },
    date: `Date`,
    error_detail: `An error has occurred. Please contact the public relations department.`,
  },
  divider: {
    or_login_with: "または、ログイン",
  },
  page: {
    AllIncidentView: {
      title_1: `スタッフの到着にかかる時間:`,
      title_2: `スタッフの到着予定時刻:`,
      title_3: `スタッフが現場に到着した時間:`,
      title_4: `現場での所要時間:`,
      waiting_1: `スタッフが到着中`,
      waiting_2: `少々お待ちください...`,
      status_1: `事件の処理に成功しました`,
      status_2: `作業終了`,
      status_3: `報告がキャンセルされました`,
    },
    PermissionView: {
      app_detail: `ヘルプにアクセスするには、次のようにアプリケーションのアクセス権を常に有効にしてください。`,
      web_detail: `<hr class="my-2" />
      <h2 class="ttp-text-lg font-medium text-center">ブラウザで権限を有効にする手順</h2>
      <hr class="my-2" />
      <div>1. ブラウザを開く</div>
      <div>2. 画面の右上の3つの点をクリックしてメニューを開く</div>
      <div>3. "設定" (Settings) を選択</div>
      <div>4. 下にスクロールして "プライバシーとセキュリティ" (Privacy and security) をクリック</div>
      <div>5. "サイトの設定" (Site settings) を選択</div>
      <hr class="my-2" />
      <h3 class="ttp-text-base font-medium">権限:</h3>
      <div>- 位置情報: "位置情報" をクリックし、"サイトが位置情報をリクエストできるようにする" を有効にする</div>
      <div>- カメラ: "カメラ" をクリックし、"サイトがカメラの使用をリクエストできるようにする" を有効にする</div>
      <div>- マイク: "マイク" をクリックし、"サイトがマイクの使用をリクエストできるようにする" を有効にする</div>
      <div>- 通知: "通知" をクリックし、"サイトが通知を送信できるようにする" を有効にする</div>
      <div>- 写真: サイトがファイルにアクセスする権限をリクエストする場合、サイトごとにファイルアクセス権限を設定できます</div>
    `,
    },
    NewsInternalDetailView: {
      not_found_gallery: `アルバムが見つかりません`,
      gallery: `ギャラリー`,
    },
    NewsDetailView: {
      not_found_gallery: `アルバムが見つかりません`,
    },
    NewsInternalView: {
      gallery: `ギャラリー`,
    },
    NewsView: {
      title: `ニュース`,
      not_found: `ニュースが見つかりません`,
    },
    PreviewView: {
      title: `プレビュー`,
    },
    HomeView: {
      title: `ホーム`,
      hello_txt: `こんにちは`,
      welcome_txt: `タイへようこそ.`,
      welcome_detail_txt: `観光警察、あなたの最初の友達`,
      incident_status: `インシデントステータス`,
      popular_places: `人気の場所`,
      news: `ニュース`,
      box_title: `観光警察に事件を報告しますか？`,
      box_detail: `観光警察に質問に答えるにはボタンを押してください`,
      sos_title: `緊急の助けが必要ですか？`,
      sos_detail: `緊急電話をかけるにはボタンを押してください`,
      sos_btn: `SOS`,
      see_more: `もっと見る`,
    },
    LoginView: {
      title: "ログイン",
      subtitle: "コマンドセンターへようこそ",
      agent_subtitle: "Welcome to Agent",
      lang: "言語を選ぶ",
    },
    ForgotPasswordView: {
      title: "パスワードをお忘れですか",
      detail_step_1: "Don't worry, we'll get you back! Your email.",
      detail_step_2: "メールに送信された OTP を入力してください。",
      detail_step_3: "新しいパスワードを設定してください",
      detail_step_verify: "本人確認",
      create: "新しいパスワードを作成する",
      desc: "確認プロセスのメールを入力します",
      email: "We'll send a 6-digit code to your email.",
      otp: "コード検証 OTP",
      otp_send_to: "コードが送信されました",
      otp_resend: "...でまたコードを送ります",
    },
    ResetPasswordView: {
      title: "パスワードが変更された",
      detail: "パスワードが正常に変更されました",
    },
    NotFoundView: {
      status_number: "404",
      title: `これは気まずいですね...`,
      detail: `お探しのページが見つからないようです。移動されたか削除された可能性があります。申し訳ありませんが、ウェブアプリケーションで検索をお試しください。`,
    },
    MaintenanceView: {
      title: "進行中の改善",
      detail: `少々お待ちください。ウェブサイトを改良中です。すぐにトーストしたてのベーグルよりも素晴らしい姿で戻ってきます。ご辛抱いただきありがとうございます。すぐに本当にクールなものをお届けします！`,
    },
    TouristManageView: {
      record_title: "接触履歴（最新の）",
    },
    OverviewView: {
      title_left: "通知",
      title_right: "緊急SOS通知",
      tab_left: "新しいインシデントレポート",
      tab_right: "管理された",
      not_found: "新しいイベントはありません",
      tab_new: "新しい",
      tab_in_progress: "進捗",
      tab_successful: "編集が成功しました",
    },
    IncidentManageView: {
      title: "インシデント管理",
      document_number: "書類番号",
      receive_date: "受信日",
      information: "情報",
      history: "履歴の管理",
      emergency_contact: "緊急連絡",
    },
    IncidentNoticeView: {
      history: "歴史",
      number_code: "登録する",
    },
    RegisterView: {
      title: "登録する",
      desc: "メールで",
      create: "アカウントを作成する",
      login: "アカウントがすでにありますか？",
    },
    ChatView: {
      title: "保護者",
      location_title: "位置",
      reply_message: `メッセージに返信`,
      make_a_call: `電話をかける`,
      hang_up: `電話を切る`,
      answer_a_call: `電話に出る`,
      response_voice_call: `音声通話で応答`,
      response_bot: `ガーディアン（ボット）からの応答`,
      response_police: `警察からの応答`,
      end_conversation: `会話の終了`,
      detail: `新しい事件報告があればご連絡ください`,
    },
    ChatManageView: {
      title: "ガーディアン",
      location_title: "所在地",
    },
    MessageView: {
      title: "メッセージ",
    },
    PreChatView: {
      title: "インシデントを報告する",
      sub_title: "インシデントを報告する",
      detail: "事件を報告してタイ観光警察に援助を求めてください。お知らせではありません。",
    },
    VoiceCallView: {
      hungUp: {
        hero: 'The call has ended.',
        title: 'Have you finished reporting your issue?',
        thankYou: {
          title: 'Thank you for information',
          reportOn: 'Your report no. is',
          description: 'Our tourist police will assist you as soon as possible. You can check the status of your report in history',
        },
        button: {
          confirm: 'Yes, I have',
          callAgain: 'Call 1155',
        }
      }
    },
    HistoryPoliceTouristAndAgentView: {
      title: "事件の履歴",
    },
    HistoryDetailPoliceTouristAndAgentView: {
      title: "事件の履歴",
      report_no: "レポート番号",
      report_info: "レポート情報",
      not_found_detail: "ここには事件歴はありません。",
    },
    ReportIncidentView: {
      title: "報告",
      guardian_chatbot: "ガーディアンチャットボット",
      report_incident_with_chatbot: "チャットボットでインシデントを報告する",
      sos_report: "SOSレポート",
      share_location: "今すぐ位置情報を観光警察に共有してください",
    },
    ProfileMenuView: {
      title: "プロフィール",
    },
    ProfileEditTouristView: {
      title: "プロファイル編集",
    },
    ProfileSocialMediaAccountView: {
      title: "ソーシャルメディアアカウント",
      link_txt: "リンク",
    },
    ProfileUnlinkView: {
      link_google_txt: `リンクされた Google アカウント`,
      link_fb_txt: `リンクされた Facebook アカウント`,
      link_apple_txt: `リンクされた Apple アカウントt`,
    },
    SettingContactUsView: {
      title: "お問い合わせ",
    },
    SettingLanguageView: {
      title: "言語",
    },
    SettingNotificationView: {
      title: "通知",
      push_notifications_txt: "プッシュ通知",
      chat_txt: "チャット通知",
    },
    SettingSecurityPrivacyChangePasswordView: {
      title: "パスワードを変更する",
    },
    SettingSecurityPrivacyView: {
      title: "セキュリティとプライバシー",
      allow_location: "現在地の追跡を許可",
      allow_camera: "カメラへのアクセスを許可",
      allow_photo: "フォトアルバムへのアクセスを許可",
      allow_mic: "マイクへのアクセスを許可",
    },
    PopularPlaceView: {
      not_found: `No Place`,
    },
    RegisterSuccessView: {
      head: `Success`,
      title: `Verification Success!`,
      welcome: `Welcome`,
      detail_1: `We will help you to the best of our ability. Thank you for trusting us to look after you.`,
      detail_2: `Your account is ready to use. You will be redirected to the Home page when you click.`,
    },
  },
  btn: {
    btn_permission: `プライバシー設定`,
    btn_readmore: `続きを読む`,
    btn_skip: `スキップ`,
    btn_go_home: `ホームページに戻る`,
    btn_background_check: `バックグラウンドチェック`,
    btn_unlink: `リンク解除`,
    btn_destination_stoped_incident: `目的地に到着し、事件を停止`,
    btn_repair_report: `編集`,
    btn_google_map: `Googleマップを見る`,
    btn_reset: `リセット`,
    btn_receieve_report: `タスクを終了`,
    btn_map: `地図`,
    btn_share: `共有`,
    btn_register: `登録`,
    btn_create_user: `ユーザーを作成`,
    btn_submit: `送信`,
    btn_confirm: `確認`,
    btn_select: `ファイルを選択`,
    btn_delete: `このデータを削除`,
    btn_delete_file: `ファイルを削除`,
    btn_delete_image: `画像を削除`,
    btn_delete_account: `アカウントを削除`,
    btn_send_email: `招待メールを送信`,
    btn_cancel: `キャンセル`,
    btn_save: `保存`,
    btn_next: `次へ`,
    btn_accept: `受け入れる`,
    btn_confirm_save: `保存を確認`,
    btn_incident: `事件`,
    btn_record: `管理履歴`,
    btn_assign: `割り当て`,
    btn_report: `報告書を送信`,
    btn_edit: `編集`,
    btn_back: `戻る`,
    btn_manage: `管理`,
    btn_export: `データをエクスポート`,
    btn_new_case: `事件を作成`,
    btn_view_report: `報告書を見る`,
    btn_back_dashboard: `ダッシュボードに戻る`,
    btn_forgot_password: `パスワードを忘れましたか`,
    btn_sign_in: `サインイン`,
    btn_change_password: `パスワードを変更`,
    btn_back_login: `ログインページに戻る`,
    btn_not_receive: `コードを受け取っていない場合`,
    btn_resend_code: `コードを再送信`,
    btn_search: `検索`,
    btn_reject: `拒否`,
    btn_all: `すべて見る`,
    btn_receive: `受け取る`,
    btn_view_notification: `通知を見る`,
    btn_manage_history: `管理履歴`,
    btn_chat: `チャットに移動`,
    btn_login_with_phone: `電話番号で続行`,
    btn_fb: `Facebookで続行`,
    btn_google: `Googleで続行`,
    btn_apple: `Appleで続行`,
    btn_successful: `事件報告書を書く`,
    btn_edit_completed: `結果を保存`,
    btn_location_completed: `目的地に到着し、事件を停止`,
    btn_edit_report: `報告書を送信`,
    btn_approve_report: `報告書を承認`,
    btn_cancel_report: `報告書をキャンセル`,
    btn_select_default: `選択`,
    btn_non: `未指定`,
    btn_no: `いいえ`,
    btn_fill_in_manually: `手動で入力`,
    btn_start_report: `報告を開始`,
    btn_get_report: `緊急事件を報告`,
    btn_logout: `はい、ログアウト`,
    btn_news: `ニュースを作成`,
    btn_submit_work: `結果を保存`,
    btn_alert: `通知を作成`,
    btn_download: `ダウンロード`,
  },
  popup: {
    cancel: {
      cancel_title: "キャンセル",
      cancel_detail: "あなたが、この最新版をキャンセルすることは確実ですか？",
    },
    reject: {
      reject_title: `報告のキャンセルを確認`,
      reject_detail: `本当にこの報告をキャンセルしますか？`,
    },
    error: {
      delete_title: "削除の確認",
      delete_detail: "あなたが、このデータを削除することは確実ですか？",
      delete_file_image_title: "画像削除の確認",
      delete_file_image_detail: "あなたが、この絵を削除することは確実ですか？",
    },
    success: {
      no_criminal: `犯罪履歴が見つかりません`,
      title: `保存に成功しました`,
      detail: `次のステップに進むことができます`,
      history_detail: `事件報告書を遡って確認できます`,
      receive_title: `受信に成功しました`,
      receive_detail: `次のステップに進むことができます`,
      edit_receive_title: `責任者の修正に成功しました`,
      edit_receive_detail: `責任者が次のステップに進むことができます`,
      reject_title: `報告を拒否しました`,
      email_title: `メールが編集されました`,
      email_detail: `新しいメールでログインできます。再度ログインしてください`,
      delete_title: `データの削除に成功しました`,
      delete_detail: `このデータは削除されました`,
      delete_user_title: `アカウントの削除に成功しました`,
      delete_user_detail: `アカウントは削除されました`,
      delete_image_title: `画像の削除に成功しました`,
      delete_image_detail: `画像は削除されました`,
      close_title: `作業が正常に終了しました`,
      close_detail: ``,
      edit_title: `編集に成功しました`,
      edit_detail: ``,
      send_title: `報告書の送信に成功しました`,
      send_detail: ``,
      waiting_title: `送信成功`,
      closed_title: `作業が正常に終了しました`,
      closed_detail: `次のステップに進むことができます`,
      confirm_title: `報告書が正常に承認されました`,
      confirm_detail: ``,
      receive_suppressed_title: `事件が正常に停止しました`,
      receive_suppressed_detail: `次のステップに進むことができます`,
      submit_title: `受付に成功しました`,
      submit_detail: `次のステップに進むことができます`,
      copy_title: `コピーに成功しました`,
      send_wait_title: `保存に成功しました`,
      save_receive_title: `作業が正常に保存されました`,
      tel1155_title: `通知`,
      tel1155_detail: `この通話は有料です。受け入れますか？`,
    },
    caution: {
      default_title: "ごめん",
      delete_title: "ごめん",
      default_detail: "現時点では報告はありません",
      recipient_detail: "対象はすでに存在しているか、情報が変更されています",
      completed_detail: "このアイテムがすでに編集されたので、完成されることができません",
      delete_image_detail: "画像を削除できません",
      delete_detail: "削除できません",
      delete_user_detail: "このアカウントは削除できません",
      error_detail: "トランザクションを完了できないエラーが発生しました",
      duplicate_detail: "データはシステム内にすでに存在します",
    },
    textarea: {
      process_title: "処理結果",
      process_placeholder: "処理結果を入力してください",
      reject_title: "操作の結果を拒否する",
    },
    logout: {
      title: "ログアウト",
      detail: "ログアウトしてもよろしいですか?",
    },
  },
  form: {
    address: {
      label: "住所",
      placeholder: "住所を入力してください",
    },
    opening_hours: {
      label: "営業時間",
      placeholder: "営業時間を入力してください",
    },
    tourist_types: {
      label: "観光客のタイプ",
      placeholder: "観光客のタイプを入力してください",
    },
    types: {
      label: "タイプ",
      placeholder: "タイプを入力してください",
    },
    map: {
      label: "地図",
      placeholder: "地図を入力してください",
    },
    latitude: {
      label: "緯度",
      placeholder: "緯度を入力してください",
    },
    longitude: {
      label: "経度",
      placeholder: "経度を入力してください",
    },
    area: {
      label: "エリア",
      placeholder: "エリアを入力してください",
    },
    metadata: {
      label: "事件現場まで何分かかりますか（分単位で数字を入力してください）",
      placeholder: "事件現場まで何分かかるか入力してください",
    },
    contentAt: {
      label: "ニュースの日付",
      placeholder: "ニュースの日付を選択してください",
    },
    publishedAt: {
      label: "表示日",
      placeholder: "表示日を選択してください",
    },
    thumbnail: {
      label: "画像",
      placeholder: "画像を選択してください",
    },
    gallery: {
      label: "フォトアルバム",
      placeholder: "画像を選択してください",
    },
    published: {
      label: "表示ステータス",
      placeholder: "表示ステータスを選択してください",
    },
    isInternal: {
      label: "内部ニュースのステータス",
      placeholder: "内部ニュースのステータスを選択してください",
    },
    translations: {
      label: "タイ語のタイトル",
      placeholder: "タイ語のタイトルを入力してください",
    },
    translations_cn: {
      label: "中国語の記事",
      placeholder: "中国語の記事を入力してください",
    },
    translations_en: {
      label: "英語の記事",
      placeholder: "英語の記事を入力してください",
    },
    translations_jp: {
      label: "日本語の記事",
      placeholder: "日本語の記事を入力してください",
    },
    translations_kr: {
      label: "韓国語の記事",
      placeholder: "韓国語の記事を入力してください",
    },
    translations_ru: {
      label: "ロシア語の記事",
      placeholder: "ロシア語の記事を入力してください",
    },
    translations_th: {
      label: "タイ語の記事",
      placeholder: "タイ語の記事を入力してください",
    },
    channel: {
      label: `チャネル`,
      placeholder: `選択してください`,
    },
    is_track_location: {
      label: `位置情報のトラッキングを許可`,
      placeholder: `選択してください`,
    },
    is_camera: {
      label: `カメラアクセスを許可`,
      placeholder: `選択してください`,
    },
    is_photo: {
      label: `写真アクセスを許可`,
      placeholder: `選択してください`,
    },
    is_microphone: {
      label: `マイクアクセスを許可`,
      placeholder: `選択してください`,
    },
    is_push_notification: {
      label: `プッシュ通知を許可`,
      placeholder: `選択してください`,
    },
    is_chat: {
      label: `チャットアクセスを許可`,
      placeholder: `選択してください`,
    },
    emergency_contact: {
      label: `緊急連絡先`,
      placeholder: `緊急連絡先を入力してください`,
    },
    images: {
      label: `画像`,
      placeholder: `画像を選択してください`,
    },
    region: {
      label: `地域`,
      placeholder: `地域を選択してください`,
    },
    message: {
      label: `Aa`,
      placeholder: `Aa`,
    },
    numberic: {
      label: ``,
      placeholder: ``,
    },
    location_search: {
      label: `事件現場`,
      placeholder: `事件現場を検索`,
    },
    notification_date: {
      label: `通知日`,
      placeholder: `通知日を選択してください`,
    },
    case_number: {
      label: `レポート番号`,
      placeholder: `レポート番号を入力してください`,
    },
    friendly_category: {
      label: `観光客からの事件カテゴリ`,
      placeholder: `-`,
    },
    story_type: {
      label: `事件タイプ`,
      placeholder: `事件タイプを選択してください`,
    },
    province_area: {
      label: `県エリア`,
      placeholder: `県エリアを選択してください`,
    },
    recipient: {
      label: `受信者`,
      placeholder: `受信者を選択してください`,
    },
    passport_number: {
      label: `パスポート番号`,
      placeholder: `パスポート番号を入力してください`,
    },
    number: {
      label: `番号`,
      placeholder: `番号を入力してください`,
    },
    gender: {
      label: `性別`,
      placeholder: `性別を入力してください`,
    },
    nationality: {
      label: `国籍`,
      placeholder: `国籍を選択してください`,
    },
    country: {
      label: `国`,
      placeholder: `国を選択してください`,
    },
    app_channel: {
      label: `登録チャネル`,
      placeholder: `登録チャネルを選択してください`,
    },
    notification_channel: {
      label: `通知チャネル`,
      placeholder: `通知チャネルを選択してください`,
    },
    phone: {
      label: `電話番号`,
      placeholder: `電話番号を入力してください`,
    },
    informer_phone: {
      label: `通知者の電話番号`,
      placeholder: `通知者の電話番号を入力してください`,
    },
    identification_type: {
      label: `身分証明の種類`,
      placeholder: `身分証明の種類を選択してください`,
    },
    type_code: {
      label: ``,
      placeholder: ``,
    },
    id: {
      label: `ID`,
      placeholder: `IDを入力してください`,
    },
    email: {
      label: `メール`,
      placeholder: `メールを入力してください`,
    },
    nowPassword: {
      label: `現在のパスワード`,
      placeholder: `現在のパスワードを入力してください`,
    },
    password: {
      label: `パスワード`,
      placeholder: `パスワードを入力してください`,
    },
    newPassword: {
      label: `新しいパスワード`,
      placeholder: `新しいパスワードを入力してください`,
    },
    repeatPassword: {
      label: `新しいパスワードを確認`,
      placeholder: `新しいパスワードを確認してください`,
    },
    remember_me: {
      label: `ログイン状態を維持`,
      placeholder: ``,
    },
    status: {
      label: `ステータス`,
      placeholder: `ステータスを選択してください`,
    },
    role: {
      label: `役割`,
      placeholder: `役割を選択してください`,
    },
    division: {
      label: "部門",
      placeholder: "部門を選択してください",
    },
    subdivision: {
      placeholder: "下位部門を選択してください",
    },
    station: {
      placeholder: "ステーションを選択してください",
    },
    responsibleArea: {
      label: "責任地域",
      placeholder: "責任地域を選択してください",
    },
    keyword: {
      label: `検索...`,
      placeholder: ``,
    },
    image_name: {
      label: `画像`,
      placeholder: `画像の名前を入力してください`,
    },
    informer_name: {
      label: `通知者の名前`,
      placeholder: `通知者の名前を入力してください`,
    },
    informer_type: {
      label: `通知者の種類`,
      placeholder: `通知者の種類を選択してください`,
    },
    name: {
      label: `名前`,
      placeholder: `名前を入力してください`,
    },
    title: {
      label: `タイトル`,
      placeholder: `タイトルを入力してください`,
    },
    title_news: {
      label: `タイトル`,
      placeholder: `テキストを入力してください`,
    },
    title_alert: {
      label: `タイトル`,
      placeholder: `テキストを入力してください`,
    },
    description: {
      label: `説明`,
      placeholder: `テキストを入力してください`,
    },
    detail: {
      label: `記事`,
      placeholder: `テキストを入力してください`,
    },
    firstName: {
      label: `名前`,
      placeholder: `名前を入力してください`,
    },
    lastName: {
      label: `苗字`,
      placeholder: `苗字を入力してください`,
    },
    idCard: {
      label: `身分証明書番号`,
      placeholder: `身分証明書番号を入力してください`,
    },
    isOpenAgent: {
      label: `エージェントプラットフォームを有効にする`,
      placeholder: ``,
    },
    touristDetail: {
      label: `事件の詳細（観光客から）`,
      placeholder: `事件の詳細を入力してください（観光客から）`,
    },
    notificationChannel: {
      label: `通知チャネル`,
      placeholder: ``,
    },
    officerDetail: {
      label: `事件の詳細（警察用）`,
      placeholder: `詳細を入力してください`,
    },
    no: {
      label: `コード`,
      placeholder: `コードを入力してください`,
    },
    subject: {
      label: `件名`,
      placeholder: `件名を選択してください`,
    },
    important: {
      label: `重要度`,
      placeholder: `重要度を選択してください`,
    },
    district: {
      label: `地区`,
      placeholder: `地区を選択してください`,
    },
    sub_district: {
      label: `サブ地区`,
      placeholder: `サブ地区を選択してください`,
    },
    location_detail: {
      label: `場所の詳細`,
      placeholder: `場所の詳細を入力してください`,
    },
    pin_location: {
      label: `ピンの場所`,
      placeholder: ``,
    },
    submit_story: {
      label: `ストーリーを送信`,
      placeholder: ``,
    },
    close_case: {
      label: `ケースを閉じる`,
      placeholder: ``,
    },
    send_responsibility: {
      label: `責任地域に送信`,
      placeholder: `責任地域を選択してください`,
    },
    agency: {
      label: `機関`,
      placeholder: ``,
    },
    police_inspector: {
      label: `警察検査`,
      placeholder: ``,
    },
    result: {
      label: `結果`,
      placeholder: ``,
    },
    responsible_person: {
      label: `責任者`,
      placeholder: `責任者を選択してください`,
    },
    updated_date: {
      label: `最新の更新`,
      placeholder: ``,
    },
    estimate_time: {
      label: `おおよその時間`,
      placeholder: ``,
    },
    subject_user: {
      label: `責任者`,
      placeholder: ``,
    },
    assign_user: {
      label: `割り当て者`,
      placeholder: ``,
    },
    inspector_user: {
      label: `検査官`,
      placeholder: ``,
    },
    type: {
      label: `事件タイプ`,
      placeholder: `事件タイプを選択してください`,
    },
    startAt: {
      label: `開始日`,
      placeholder: `開始日を選択してください`,
    },
    endAt: {
      label: `終了日`,
      placeholder: `終了日を選択してください`,
    },
    information_user: {
      label: `通知者情報`,
      placeholder: `通知者情報を選択してください`,
    },
    admin_user: {
      label: `管理者`,
      placeholder: `管理者を選択してください`,
    },
    control_user: {
      label: `制御者`,
      placeholder: `制御者を選択してください`,
    },
    responsibleAreaCodes: {
      label: "責任地域",
      placeholder: "責任地域を選択してください",
    },
    locales: {
      label: "言語",
      placeholder: "言語を選択してください",
    },
  },
  error: {
    password: "パスワードは少なくとも 6 文字でなければなりません",
    invalid_credential: "ログインできません。 もう一度試してください",
  },
  caution: {
    user_1: `注意: エージェント操作システムが有効になっている場合、責任領域を選択する必要があります`,
    password_1: `パスワードは、大文字、小文字、数字を含む6文字以上である必要があります`,
    picture_1: `推奨サイズ: 画像サイズ 200 x 200 ピクセル`,
    picture_2: `(最大 5MB) ファイル形式: JPG (JPEG), PNG`,
    picture_1_2: `推奨サイズ: 画像サイズ 490 x 385 ピクセル`,
    picture_2_2: `(最大 5MB) ファイル形式: JPG (JPEG), PNG`,
    delete_title: ``,
    email_1: `このメールアドレスはアカウントの確認に使用されます`,
  },
  role: {
    ADMIN: "アドミニストレータ",
    CENTRAL_OFFICER: "1155役員",
    OFFICER: "最高責任者",
    REPORTER: "レポーター",
    AGENT: "エージェント",
  },
  incident_status: {
    waiting: "待機",
    in_progress: "進行中",
    canceled: "完了",
  },
  lang: {
    th: "ไทย",
    en: "英語",
    cn: "中国大陆",
    ru: "Россия",
    kr: "대한민국",
    jp: "日本",
  },
  progress: {
    processing_time: "処理時間",
    period_time: "期間",
  },
  go_to_chat: "Go back to chat",
  sos: {
    title: 'SOSシステムは、以下の場合のみインシデントの報告に使用できる:',
    conditions: [
      '身体的暴行を受けるなど、重傷を負ったり身体に危害を加えられたりすること。',
      '泥棒や強盗に入られるなど、財産に重大な損害を受ける。',
      '重大事故',
      '身動きが取れない、自力ではどうすることもできないなどの重篤な病気',
    ],
    description: '上記の条件に当てはまらない場合は、観光警察アプリケーションのチャットまたはホットライン 1155 で通報することができる。',
    button: {
      confirm: '確認',
      cancel: 'キャンセル',
    },
  },
  errorMessage: {
    duplicateEmail: '重複したメール'
  }
};
