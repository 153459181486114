<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px ttp-bg-graident-2 text-ttp-text-primary">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen">
          <div class="flex items-center justify-center mx-2 my-5">
            <button class="w-10 text-white text-left" @click.prevent="goBack">
              <ChevronLeftIcon class="flex-none inline-block ttp-icon-inside-box-02" />
            </button>
            <div class="w-full text-white font-bold text-center">
              <h1 class="ttp-text-xl">{{ t("page.RegisterView.title") }}</h1>
            </div>
            <div class="w-10"></div>
          </div>

          <form @submit.prevent="onSubmit">
            <div class="grid grid-cols-12 gap-2 h-full">
              <div class="col-start-1 col-end-13 h-full">
                <div class="ttp-layout gradient-border w-full h-full mt-0 p-4">
                  <h1 class="text-black font-bold ttp-text-2xl">{{ t("page.RegisterView.create") }}</h1>
                  <div class="grid grid-cols-12 gap-2 py-4">
                    <div class="flex items-center justify-center col-start-1 col-end-13 md:col-start-1 md:col-end-13 mb-6">
                      <div class="swipe-photo relative inline-flex h-36 w-36 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                        <UserIcon v-if="!imageUrl?.length" class="inline-block h-20 w-20" />
                        <a v-else :href="imageUrl" target="_blank">
                          <img :src="imageUrl" class="object-cover object-center w-36 h-36 max-w-full rounded-full float-right" />
                        </a>
                        <input id="file" type="file" accept="image/*" hidden @change="uploadImage" />
                        <label for="file" class="cursor-pointer text-center absolute bottom-0 right-0 bg-gray-100 rounded-full bg-ttp-primary ttp-icon-inside-box-02">
                          <PencilIcon class="inline-block ttp-icon-inside-box-03 text-white" />
                        </label>
                      </div>
                    </div>

                    <FormInput v-model="name" :label="`${t('form.name.label')} (English Only)`" :placeholder="t('form.name.placeholder')" :is-disable="loading" :error-message="errorMessage.name" class="col-start-1 col-end-13 md:col-start-1 md:col-end-13" required @change="errorMessage.name = ''" />
                    <FormSelect v-model="nationality" :items="nationalities" :label="t('form.nationality.label')" :placeholder="t('form.nationality.placeholder')" :is-disable="loading" :error-message="errorMessage.nationality" class="col-start-1 col-end-13 md:col-start-1 md:col-end-13" required @change="errorMessage.nationality = ''" />
                    <FormSelect v-model="country" :items="countries" :label="t('form.country.label')" :placeholder="t('form.country.placeholder')" :is-disable="loading" :error-message="errorMessage.country" class="col-start-1 col-end-13 md:col-start-1 md:col-end-13" required @change="errorMessage.country = ''" />
                    <FormSelect v-model="gender" :items="genders" :label="t('form.gender.label')" :placeholder="t('form.gender.placeholder')" :is-disable="loading" :error-message="errorMessage.gender" class="col-start-1 col-end-13 md:col-start-1 md:col-end-13" @change="errorMessage.gender = ''" />
                  </div>
                  <div class="ttp-between border-t-2 pt-4">
                    <div class="flex gap-2 w-full">
                      <ButtonDefaultComponent :is-loading="loading" :is-disable="loading" :title="t('btn.btn_next')" :class-enum-name="ENUM_COLOR.DEFAULT_1" :size="ENUM_SIZE.SMALL" class-tag-name="w-full m-auto" is-visible is-icon is-submit />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import FormInput from "@/components/Forms/Input/FormInput.vue";
import FormSelect from "@/components/Forms/SelectBox/FormSelect.vue";
import { ENUM_COLOR, ENUM_SIZE } from "@/configs/enums/enum";
import { useAuthStore } from "@/stores/PoliceTouristAndAgent/useAuthStore";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { ChevronLeftIcon, PencilIcon, UserIcon } from "@heroicons/vue/24/solid";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import * as yup from "yup";

const router = useRouter();
const { t } = useI18n();

const authStore = useAuthStore();
const masterDataStore = useMasterDataStore();
const sharedStore = useSharedStore();

const nationalities = computed(() => masterDataStore.itemNationalities());
const countries = computed(() => masterDataStore.itemCountries());
const genders = computed(() => masterDataStore.itemGenders());

const imageUrl = ref<string>();
const loading = ref<boolean>(false);

const name = ref<string>("");
const nationality = ref<string>("");
const country = ref<string>("");
const gender = ref<string>("NONE");

const errorMessage = ref<{ [key: string]: string }>({
  name: "",
  nationality: "",
  country: "",
  gender: "",
});

const onSubmit = async () => {
  const authStore = useAuthStore();
  const schema = yup.object().shape({
    name: yup.string().required(),
    nationality: yup.string().required(),
    country: yup.string().required(),
  });

  const payload = {
    name: name.value,
    nationality: nationality.value,
    country: country.value,
    gender: gender.value.length ? gender.value : 'NONE',
    locale: sharedStore.locale
  };

  try {
    loading.value = true;
    Object.keys(errorMessage.value).forEach((k) => (errorMessage.value[k] = ""));

    await schema.validate(payload, {
      abortEarly: false,
    });

    authStore.updateUser = payload;

    router.push({ name: "TermConditionsView" });
  } catch (e: any) {
    if (e instanceof yup.ValidationError == false) {
      return;
    }

    e.inner?.forEach((inner) => {
      if (!inner.path) {
        return;
      }

      errorMessage.value[inner.path] = inner.message;
    });

    return;
  } finally {
    loading.value = false;
  }
};

const goBack = async () => {
  await FirebaseAuthentication.signOut();
  router.push({ name: "HomeView" });
};

const uploadImage = () => {};

onMounted(async () => {
  if (!authStore.user) {
    await router.push({ name: 'RegisterView' })
    return
  }

  if (!nationalities.value.length) {
    masterDataStore.listNationality();
  }

  if (!countries.value.length) {
    masterDataStore.listCountry();
  }
});
</script>
