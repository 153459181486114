import { defineStore } from "pinia";
import { FirebaseAuthentication, Persistence } from "@capacitor-firebase/authentication";
import { auth } from "@/configs/plugins/firebase";
import { fetchAuth } from "@/configs/fetches/PoliceCommandCenter/fetchAuth";
import { fetchAuth as fetchAgentAuth } from "@/configs/fetches/PoliceTouristAndAgent/Agent/fetchAuth";
import type { typeGetUser } from "@/configs/types/User/typeUser";
import { isCommandCenterApp, removeLocalStorage, setLocalStorage, inArray } from "@/configs/helpers/MainHelper";
import { getProjectType } from "@/routers";
import { storeName as storeNamePoliceTouristAndAgent } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { storeName as storeNamePoliceCommandCenter } from "@/stores/PoliceCommandCenter/useSharedStore";
import { ENUM_APP_CATEGORY_NAME } from "@/configs/enums/enum";

type State = {
  user: typeGetUser | null;
  token: string | null;
  deviceToken: string | null;
  registerProfile: any;
  sharedStoreName: string;
};

export type Credential = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export const AUTH_TOKEN_KEY = "authToken-PoiliceCommandCenter";
export const AUTH_PROFILE_KEY = "authProfile-PoiliceCommandCenter";
export const AUTH_USER_KEY = "authUser-PoiliceCommandCenter";

const storeName = `useAuthStore-${import.meta.env.VITE_APP_VERSION}`;

export const useAuthStore = defineStore(storeName, {
  state: (): State => ({
    user: null,
    token: null,
    deviceToken: null,
    registerProfile: null,
    sharedStoreName: inArray(getProjectType(), [ENUM_APP_CATEGORY_NAME.COMMAND]) ? storeNamePoliceCommandCenter : storeNamePoliceTouristAndAgent,
  }),
  actions: {
    async setRememberMe(isRememberMe: boolean) {
      await FirebaseAuthentication.setPersistence({
        persistence: isRememberMe ? Persistence.BrowserLocal : Persistence.BrowserSession,
      });
    },
    async get() {
      const fetch = isCommandCenterApp ? new fetchAuth() : new fetchAgentAuth();
      this.user = await fetch.Get();

      await setLocalStorage(AUTH_USER_KEY, JSON.stringify(this.user));
    },
    async login({ email, password }: Credential) {
      if (!email.includes("@")) {
        email = `th.touristpolice+${email}@gmail.com`;
      }
      const result = await FirebaseAuthentication.signInWithEmailAndPassword({
        email,
        password,
      });

      const { token } = await FirebaseAuthentication.getIdToken();
      this.token = token;

      await setLocalStorage(AUTH_TOKEN_KEY, this.token);
      await setLocalStorage(`${this.sharedStoreName}:getProjectType`, getProjectType());

      await setLocalStorage(
        AUTH_PROFILE_KEY,
        JSON.stringify({
          email: result?.user?.email,
          phoneNumber: result?.user?.phoneNumber,
          displayName: result?.user?.displayName,
          provider: "EMAIL",
        })
      );
      try {
        await this.get();
      } catch (e) {
        await setLocalStorage(
          AUTH_PROFILE_KEY,
          JSON.stringify({
            email: result?.user?.email,
            phoneNumber: result?.user?.phoneNumber,
            displayName: result?.user?.displayName,
            provider: "EMAIL",
          })
        );
      } finally {
        return result;
      }
    },
    addToken(token: string) {
      const fetch = isCommandCenterApp ? new fetchAuth() : new fetchAgentAuth();

      return fetch.AddDevice({
        deviceToken: token,
      });
    },
    async logout() {
      await FirebaseAuthentication.signOut();
      await auth.signOut();
      removeLocalStorage(AUTH_TOKEN_KEY);
      removeLocalStorage(AUTH_PROFILE_KEY);
      removeLocalStorage(AUTH_USER_KEY);
      removeLocalStorage(`${this.sharedStoreName}:mode`);
      removeLocalStorage(`${this.sharedStoreName}:submenuOpen`);
      removeLocalStorage(`${this.sharedStoreName}:getProjectType`);
      // removeLocalStorage(`${this.sharedStoreName}:locale`);
      removeLocalStorage(`${this.sharedStoreName}:deviceToken`);

      this.token = null;
      this.user = null;
      this.registerProfile = null;
    },
  },
  getters: {
    displayName: ({ user }: State): string => {
      const [content] = user?.content || [];
      return content?.shortName || "";
    },
    displayRole: ({ user }: State): string => {
      return user?.role || "-";
    },
  },
});
