import AdminLayoutView from "@/layouts/AuthenticationLayouts/PoliceCommandCenter/AdminLayoutView.vue";
import MainLayoutView from "@/layouts/NotAuthenticationLayouts/MainLayoutView.vue";
import NotFoundView from "@/layouts/OtherLayouts/NotFoundView.vue";
import MaintenanceView from "@/layouts/OtherLayouts/MaintenanceView.vue";

import { ENUM_INCIDENT_STATUS } from "@/configs/enums/enum";
import LoginPoliceCommandCenterView from "@/views/PoliceCommandCenter/Authentication/LoginView.vue";
import ForgotPasswordPoliceCommandCenterView from "@/views/PoliceCommandCenter/Authentication/ForgotPasswordView.vue";
import UserView from "@/views/PoliceCommandCenter/UserManagement/UserView.vue";
import UserManageView from "@/views/PoliceCommandCenter/UserManagement/UserManageView.vue";
import ProfileEditView from "@/views/PoliceCommandCenter/Profile/ProfileEditView.vue";
import PasswordSettingView from "@/views/PoliceCommandCenter/Profile/PasswordSettingView.vue";
import TouristView from "@/views/PoliceCommandCenter/TouristManagement/TouristView.vue";
import TouristManageView from "@/views/PoliceCommandCenter/TouristManagement/TouristManageView.vue";
import OverviewView from "@/views/PoliceCommandCenter/Overview/OverviewView.vue";
import VideoWallView from "@/views/PoliceCommandCenter/Dashboard/VideoWallView.vue";
import DashboardView from "@/views/PoliceCommandCenter/Dashboard/DashboardView.vue";
import DashboardIncidentTypeView from "@/views/PoliceCommandCenter/Dashboard/DashboardIncidentTypeView.vue";
import IncidentView from "@/views/PoliceCommandCenter/IncidentManagement/IncidentView.vue";
import IncidentManageView from "@/views/PoliceCommandCenter/IncidentManagement/IncidentManageView.vue";
import IncidentNoticeView from "@/views/PoliceCommandCenter/IncidentManagement/IncidentNoticeView.vue";
import IncidentReportView from "@/views/PoliceCommandCenter/IncidentManagement/IncidentReportView.vue";
import ChatView from "@/views/PoliceCommandCenter/Chat/ChatView.vue";
import NewsView from "@/views/PoliceCommandCenter/News/NewsView.vue";
import NewsManageView from "@/views/PoliceCommandCenter/News/NewsManageView.vue";
import AlertView from "@/views/PoliceCommandCenter/Alert/AlertView.vue";
import AlertManageView from "@/views/PoliceCommandCenter/Alert/AlertManageView.vue";
import {
  PERMISSION_ANY,
  PERMISSION_DASHBOARD,
  PERMISSION_VIDEO_WALL,
  PERMISSION_ALERT,
  PERMISSION_NEWS,
  PERMISSION_OVERVIEW,
  PERMISSION_USER,
  PERMISSION_TOURIST,
  PERMISSION_INCIDENT_MY_TASK,
  PERMISSION_INCIDENT_ALL,
  PERMISSION_INCIDENT_NEW,
  PERMISSION_INCIDENT_ACCEPTED,
  PERMISSION_INCIDENT_EXTERNAL_DEPARTMENT,
  PERMISSION_INCIDENT_WAITING,
  PERMISSION_INCIDENT_CURRENT,
  PERMISSION_INCIDENT_IN_REVIEW,
  PERMISSION_INCIDENT_REPORTED,
  PERMISSION_INCIDENT_CLOSED,
  PERMISSION_INCIDENT_REJECTED,
  PERMISSION_INCIDENT_ASSIGN,
  PERMISSION_INCIDENT_UPDATE_REPORT,
  PERMISSION_INCIDENT_APPROVE,
  PERMISSION_CHAT,
} from "@/configs/permission";
import Layout from "@/layouts/AuthenticationLayouts/PoliceCommandCenter/Layout.vue";

export const routePoliceCommandCenter: any = [
  {
    path: "",
    redirect: "/login",
  },
  {
    path: "/redirect",
    name: "RedirectView",
    redirect: (to: any) => {
      const redirect = { name: to.query?.view, params: JSON.parse(to.query?.params || "") };
      return redirect;
    },
  },
  {
    path: "",
    name: "MainLayoutView",
    component: MainLayoutView,
    meta: {
      requiresAuth: false,
      guestOnly: true,
      title: "MainLayoutView",
      i18n: "layout.menu.MainLayoutView",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
      isIcon: false,
      isDropdown: false,
      isMenu: false,
      getProjectType: "command",
      activeMenu: [],
      role: PERMISSION_ANY,
    },
    children: [
      {
        path: ":pathMatch(.*)*",
        name: "NotFoundView",
        component: NotFoundView,
        meta: {
          requiresAuth: false,
          title: "Not Found",
          i18n: "layout.menu.NotFoundView",
          icon: '<svg class="hi-mini hi-x-circle inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd"/></svg>',
          isIcon: false,
          isDropdown: false,
          isHeader: false,
          isBackground: true,
          isMenu: false,
          getProjectType: "command",
          layout: "MainLayoutView",
          activeMenu: [],
          role: PERMISSION_ANY,
        },
      },
      {
        path: "maintenance",
        name: "MaintenanceView",
        component: MaintenanceView,
        meta: {
          requiresAuth: false,
          title: "Maintenance",
          i18n: "layout.menu.MaintenanceView",
          icon: "",
          isIcon: false,
          isDropdown: false,
          isHeader: false,
          isBackground: true,
          isMenu: false,
          getProjectType: "command",
          layout: "MainLayoutView",
          activeMenu: [],
          role: PERMISSION_ANY,
        },
      },
      {
        path: "login",
        name: "LoginPoliceCommandCenterView",
        component: LoginPoliceCommandCenterView,
        meta: {
          requiresAuth: false,
          title: "Login",
          i18n: "layout.menu.LoginView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: [],
          role: [],
        },
      },
      {
        path: "forgot-password",
        name: "ForgotPasswordPoliceCommandCenterView",
        component: ForgotPasswordPoliceCommandCenterView,
        meta: {
          requiresAuth: false,
          title: "Account / Forgot Password",
          i18n: "layout.menu.ForgotPasswordView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: [],
          role: PERMISSION_ANY,
        },
      },
    ],
  },
  {
    path: "/overview",
    name: "OverviewTitle",
    component: AdminLayoutView,
    meta: {
      requiresAuth: true,
      title: "OverviewTitle",
      i18n: "layout.menu_head.OverviewTitle",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
      isIcon: false,
      isDropdown: false,
      isMenu: false,
      getProjectType: "command",
      activeMenu: [],
      role: PERMISSION_OVERVIEW,
    },
    children: [
      {
        path: "",
        name: "OverviewView",
        component: OverviewView,
        meta: {
          requiresAuth: true,
          title: "Overview",
          i18n: "layout.menu.OverviewView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["OverviewView"],
          role: PERMISSION_OVERVIEW,
        },
      },
    ],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Layout,
    meta: {
      requiresAuth: true,
      i18n: "layout.menu_head.DashboardTitle",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
      isIcon: false,
      isDropdown: true,
      isMenu: true,
      getProjectType: "command",
      activeMenu: [],
      role: PERMISSION_DASHBOARD,
    },
    children: [
      ...['day', 'month', 'year'].map((timeGroup: string) => ({
        path: timeGroup,
        name: `DashboardView_${timeGroup}`,
        component: DashboardView,
        meta: {
          requiresAuth: true,
          title: "Dashboard",
          i18n: `layout.menu.DashboardView_${timeGroup}`,
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: true,
          isBackground: false,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: [`DashboardView_${timeGroup}`],
          role: PERMISSION_DASHBOARD,
          timeGroup,
        },
      })),
      {
        path: "video-wall",
        name: "VideoWallView",
        component: VideoWallView,
        meta: {
          requiresAuth: true,
          title: "Dashboard",
          i18n: "layout.menu.VideoWallView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: true,
          isBackground: false,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["VideoWallView"],
          role: PERMISSION_VIDEO_WALL,
        },
      },
      {
        path: "video-wall/fullscreen",
        name: "VideoWallViewFullScreen",
        component: VideoWallView,
        meta: {
          requiresAuth: true,
          title: "Dashboard",
          i18n: "layout.menu.VideoWallView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /></svg>',
          isHeader: true,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["VideoWallView"],
          fullScreen: true,
          role: PERMISSION_DASHBOARD,
        },
      },
      {
        path: "incident-type",
        name: "DashboardIncidentTypeView",
        component: DashboardIncidentTypeView,
        meta: {
          requiresAuth: true,
          title: "Incident Type / Dashboard",
          i18n: "layout.menu.DashboardIncidentTypeView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" /></svg>',
          isHeader: true,
          isBackground: false,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["DashboardIncidentTypeView"],
          role: PERMISSION_DASHBOARD,
        },
      },
    ],
  },
  {
    path: "/news-management",
    name: "NewsManagementTitle",
    component: AdminLayoutView,
    meta: {
      requiresAuth: true,
      title: "NewsTitle",
      i18n: "layout.menu_head.NewsTitle",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
      isIcon: false,
      isDropdown: false,
      isMenu: true,
      getProjectType: "command",
      activeMenu: [],
      role: PERMISSION_NEWS,
    },
    children: [
      {
        path: "",
        name: "NewsView",
        component: NewsView,
        meta: {
          requiresAuth: true,
          title: "Announcement",
          i18n: "layout.menu.NewsView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["NewsView", "NewsManageView"],
          queryStatus: [],
          role: PERMISSION_NEWS,
        },
      },
      {
        path: "manage/:id?",
        name: "NewsManageView",
        component: NewsManageView,
        meta: {
          requiresAuth: true,
          title: "Announcement",
          i18n: "layout.menu.NewsManageView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: false,
          isSpecial: true,
          getProjectType: "command",
          activeMenu: ["NewsView", "NewsManageView"],
          role: PERMISSION_NEWS,
        },
      },
    ],
  },
  {
    path: "/alert-management",
    name: "AlertManagementTitle",
    component: AdminLayoutView,
    meta: {
      requiresAuth: true,
      title: "AlertTitle",
      i18n: "layout.menu_head.AlertTitle",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
      isIcon: false,
      isDropdown: false,
      isMenu: true,
      getProjectType: "command",
      activeMenu: [],
      role: PERMISSION_ALERT,
    },
    children: [
      {
        path: "",
        name: "AlertView",
        component: AlertView,
        meta: {
          requiresAuth: true,
          title: "Announcement",
          i18n: "layout.menu.AlertView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["AlertView", "AlertManageView"],
          queryStatus: [],
          role: PERMISSION_ALERT,
        },
      },
      {
        path: "manage/:id?",
        name: "AlertManageView",
        component: AlertManageView,
        meta: {
          requiresAuth: true,
          title: "Announcement",
          i18n: "layout.menu.AlertManageView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: false,
          isSpecial: true,
          getProjectType: "command",
          activeMenu: ["AlertView", "AlertManageView"],
          role: PERMISSION_ALERT,
        },
      },
    ],
  },
  {
    path: "/user-management",
    name: "UserTitle",
    component: AdminLayoutView,
    meta: {
      requiresAuth: true,
      title: "UserTitle",
      i18n: "layout.menu_head.UserTitle",
      icon: '<svg class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path d="M10 9a3 3 0 100-6 3 3 0 000 6zM6 8a2 2 0 11-4 0 2 2 0 014 0zM1.49 15.326a.78.78 0 01-.358-.442 3 3 0 014.308-3.516 6.484 6.484 0 00-1.905 3.959c-.023.222-.014.442.025.654a4.97 4.97 0 01-2.07-.655zM16.44 15.98a4.97 4.97 0 002.07-.654.78.78 0 00.357-.442 3 3 0 00-4.308-3.517 6.484 6.484 0 011.907 3.96 2.32 2.32 0 01-.026.654zM18 8a2 2 0 11-4 0 2 2 0 014 0zM5.304 16.19a.844.844 0 01-.277-.71 5 5 0 019.947 0 .843.843 0 01-.277.71A6.975 6.975 0 0110 18a6.974 6.974 0 01-4.696-1.81z"/></svg>',
      isIcon: false,
      isDropdown: false,
      isMenu: true,
      getProjectType: "command",
      activeMenu: [],
      role: PERMISSION_ANY,
    },
    children: [
      {
        path: "",
        name: "UserView",
        component: UserView,
        meta: {
          requiresAuth: true,
          title: "User",
          i18n: "layout.menu.UserView",
          icon: '<svg class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["UserView", "UserManageView"],
          role: PERMISSION_USER,
        },
      },
      {
        path: "manage/:id?",
        name: "UserManageView",
        component: UserManageView,
        meta: {
          requiresAuth: true,
          title: "Manage User",
          i18n: "layout.menu.UserManageView",
          icon: '<svg class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: false,
          isSpecial: true,
          getProjectType: "command",
          activeMenu: ["UserView", "UserManageView"],
          role: PERMISSION_USER,
        },
      },
    ],
  },
  {
    path: "/tourist-user-management",
    name: "TouristTitle",
    component: AdminLayoutView,
    meta: {
      requiresAuth: true,
      title: "TouristTitle",
      i18n: "layout.menu_head.TouristTitle",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" /></svg>',
      isIcon: false,
      isDropdown: false,
      isMenu: true,
      getProjectType: "command",
      activeMenu: [],
      role: PERMISSION_TOURIST,
    },
    children: [
      {
        path: "",
        name: "TouristView",
        component: TouristView,
        meta: {
          requiresAuth: true,
          title: "Tourist User",
          i18n: "layout.menu.TouristView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["TouristView", "TouristManageView"],
          role: PERMISSION_TOURIST,
        },
      },
      {
        path: "manage/:id?",
        name: "TouristManageView",
        component: TouristManageView,
        meta: {
          requiresAuth: true,
          title: "Manage Tourist User",
          i18n: "layout.menu.TouristManageView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" /></svg>',
          isHeader: true,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["TouristView", "TouristManageView"],
          role: PERMISSION_TOURIST,
        },
      },
    ],
  },
  {
    path: "/incident-management",
    name: "IncidentTitle",
    component: AdminLayoutView,
    meta: {
      requiresAuth: true,
      title: "IncidentTitle",
      i18n: "layout.menu_head.IncidentTitle",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
      isIcon: false,
      isDropdown: false,
      isMenu: true,
      getProjectType: "command",
      activeMenu: [],
      role: [
        PERMISSION_INCIDENT_MY_TASK,
        PERMISSION_INCIDENT_ALL,
        PERMISSION_INCIDENT_NEW,
        PERMISSION_INCIDENT_ACCEPTED,
        PERMISSION_INCIDENT_EXTERNAL_DEPARTMENT,
        PERMISSION_INCIDENT_WAITING,
        PERMISSION_INCIDENT_CURRENT,
        PERMISSION_INCIDENT_IN_REVIEW,
        PERMISSION_INCIDENT_REPORTED,
        PERMISSION_INCIDENT_CLOSED,
        PERMISSION_INCIDENT_REJECTED,
        PERMISSION_INCIDENT_ASSIGN,
        PERMISSION_INCIDENT_UPDATE_REPORT,
        PERMISSION_INCIDENT_APPROVE,
      ].reduce((acc, permissions) => {
        acc.push(...permissions)
        return acc
      }, []),
    },
    children: [
      {
        path: "my-incident",
        name: "MyIncidentView",
        component: IncidentView,
        meta: {
          requiresAuth: true,
          title: "Incident",
          i18n: "layout.menu.MyIncidentView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["MyIncidentView"],
          queryStatus: [],
          role: PERMISSION_INCIDENT_MY_TASK,
        },
      },
      {
        path: "all",
        name: "AllIncidentView",
        component: IncidentView,
        meta: {
          requiresAuth: true,
          title: "Incident",
          i18n: "layout.menu.AllIncidentView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["AllIncidentView"],
          queryStatus: [],
          role: PERMISSION_INCIDENT_ALL,
        },
      },
      {
        path: "new",
        name: "IncidentView",
        component: IncidentView,
        meta: {
          requiresAuth: true,
          title: "Incident",
          i18n: "layout.menu.NewIncidentView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["IncidentView"],
          queryStatus: [ENUM_INCIDENT_STATUS.NEW],
          role: PERMISSION_INCIDENT_NEW,
        },
      },
      {
        path: "accepted",
        name: "AcceptedIncidentView",
        component: IncidentView,
        meta: {
          requiresAuth: true,
          title: "Incident",
          i18n: "layout.menu.AcceptedIncidentView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["AcceptedIncidentView"],
          queryStatus: [ENUM_INCIDENT_STATUS.ACCEPTED],
          role: PERMISSION_INCIDENT_ACCEPTED,
        },
      },
      {
        path: "forwarded",
        name: "ForwardedIncidentView",
        component: IncidentView,
        meta: {
          requiresAuth: true,
          title: "Incident",
          i18n: "layout.menu.ForwardedIncidentView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["ForwardedIncidentView"],
          queryStatus: [ENUM_INCIDENT_STATUS.FORWARDED],
          role: PERMISSION_INCIDENT_EXTERNAL_DEPARTMENT,
        },
      },
      {
        path: "waiting",
        name: "WaitingIncidentView",
        component: IncidentView,
        meta: {
          requiresAuth: true,
          title: "Incident",
          i18n: "layout.menu.WaitingIncidentView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["WaitingIncidentView"],
          queryStatus: [ENUM_INCIDENT_STATUS.WAITING],
          role: PERMISSION_INCIDENT_WAITING,
        },
      },
      {
        path: "currents",
        name: "CurrentIncidentView",
        component: IncidentView,
        meta: {
          requiresAuth: true,
          title: "Incident",
          i18n: "layout.menu.CurrentIncidentView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["CurrentIncidentView"],
          queryStatus: [ENUM_INCIDENT_STATUS.IN_PROGRESS],
          role: PERMISSION_INCIDENT_CURRENT,
        },
      },
      {
        path: "reportings",
        name: "ReportingIncidentView",
        component: IncidentView,
        meta: {
          requiresAuth: true,
          title: "Incident",
          i18n: "layout.menu.ReportingIncidentView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["ReportingIncidentView"],
          queryStatus: [ENUM_INCIDENT_STATUS.IN_REVIEW],
          role: PERMISSION_INCIDENT_IN_REVIEW,
        },
      },
      {
        path: "closing",
        name: "ClosingIncidentView",
        component: IncidentView,
        meta: {
          requiresAuth: true,
          title: "Incident",
          i18n: "layout.menu.ClosingIncidentView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["ClosingIncidentView"],
          queryStatus: [ENUM_INCIDENT_STATUS.REPORTED],
          role: PERMISSION_INCIDENT_REPORTED,
        },
      },
      {
        path: "closed",
        name: "ClosedIncidentView",
        component: IncidentView,
        meta: {
          requiresAuth: true,
          title: "Incident",
          i18n: "layout.menu.ClosedIncidentView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["ClosedIncidentView"],
          queryStatus: [ENUM_INCIDENT_STATUS.CLOSED],
          role: PERMISSION_INCIDENT_CLOSED,
        },
      },
      {
        path: "rejected",
        name: "RejectedIncidentView",
        component: IncidentView,
        meta: {
          requiresAuth: true,
          title: "Incident",
          i18n: "layout.menu.RejectedIncidentView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["RejectedIncidentView"],
          queryStatus: [ENUM_INCIDENT_STATUS.REJECTED],
          role: PERMISSION_INCIDENT_REJECTED,
        },
      },
      {
        path: "manage/:id?",
        name: "IncidentManageView",
        component: IncidentManageView,
        meta: {
          requiresAuth: true,
          title: "Incident",
          i18n: "layout.menu.IncidentManageView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: true,
          getProjectType: "command",
          activeMenu: ["IncidentView", "IncidentManageView", "IncidentNoticeView"],
          role: PERMISSION_INCIDENT_ASSIGN,
        },
        beforeEnter: (_to: any, _from: any, next: any) => {
          next();
        },
      },
      {
        path: "notice/:id?",
        name: "IncidentNoticeView",
        component: IncidentNoticeView,
        meta: {
          requiresAuth: true,
          title: "Incident",
          i18n: "layout.menu.IncidentNoticeView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["IncidentView", "IncidentManageView", "IncidentNoticeView"],
          role: PERMISSION_INCIDENT_ALL,
        },
      },
      {
        path: "report/:id?",
        name: "IncidentReportView",
        component: IncidentReportView,
        meta: {
          requiresAuth: true,
          title: "Incident",
          i18n: "layout.menu.IncidentReportView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
          isHeader: true,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: [],
          role: [...PERMISSION_INCIDENT_UPDATE_REPORT, ...PERMISSION_INCIDENT_APPROVE, ...PERMISSION_INCIDENT_REPORTED],
        },
      },
    ],
  },
  {
    path: "/incident-management",
    name: "OtherTitle",
    component: AdminLayoutView,
    meta: {
      requiresAuth: true,
      title: "OtherTitle",
      i18n: "layout.menu_head.OtherTitle",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>',
      isIcon: false,
      isDropdown: false,
      isMenu: true,
      getProjectType: "command",
      activeMenu: [],
      role: [],
    },
    children: [
      {
        path: "chat",
        name: "ChatView",
        component: ChatView,
        meta: {
          requiresAuth: true,
          title: "Chat",
          i18n: "layout.menu.ChatView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: true,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["ChatView", "ChatManageView"],
          role: PERMISSION_CHAT,
        },
      },
      {
        path: "chat/:id?",
        name: "ChatManageView",
        component: ChatView,
        meta: {
          requiresAuth: true,
          title: "Chat",
          i18n: "layout.menu.ChatView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["ChatView", "ChatManageView"],
          role: PERMISSION_CHAT,
        },
      },
    ],
  },
  {
    path: "/profile",
    name: "ProfileTitle",
    component: AdminLayoutView,
    meta: {
      requiresAuth: false,
      title: "Profile",
      i18n: "layout.menu_head.ProfileTitle",
      icon: '<svg class="hi-outline hi-user-circle inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>',
      isIcon: false,
      isDropdown: false,
      isMenu: false,
      isSpecial: false,
      getProjectType: "command",
      activeMenu: [],
      role: PERMISSION_ANY,
    },
    children: [
      {
        path: "",
        name: "ProfileEditView",
        component: ProfileEditView,
        meta: {
          requiresAuth: true,
          title: "User",
          i18n: "layout.menu.ProfileEditView",
          icon: '<svg class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["ProfileEditView", "PasswordSettingView"],
          role: PERMISSION_ANY,
        },
      },
      {
        path: "setting",
        name: "PasswordSettingView",
        component: PasswordSettingView,
        meta: {
          requiresAuth: true,
          title: "User",
          i18n: "layout.menu.PasswordSettingView",
          icon: '<svg class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" /></svg>',
          isHeader: true,
          isBackground: true,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "command",
          activeMenu: ["ProfileEditView", "PasswordSettingView"],
          role: PERMISSION_ANY,
        },
      },
    ],
  },
];
