<template>
  <div class="w-full">
    <div class="flex items-center mb-1">
      <label v-if="label?.length" :for="name" class="block mb-1 ttp-text-xs font-medium text-gray-900 dark:text-gray-50"> {{ label }} <span v-if="required" class="text-ttp-error">*</span></label>
    </div>
    <div class="relative">
      <VSelect v-model="model" :options="items" :reduce="(item: any) => item.value" :placeholder="placeholder" :disabled="disabled" :clearable="clearable" label="label" class="border border-gray-300 focus:border-ttp-primary text-gray-600 ttp-text-xs rounded-lg bg-white" :class="{ '!text-gray-400': disabled }" />
    </div>
    <small v-if="errorMessage?.length" class="relative text-ttp-text-error">{{ errorMessage }}</small>
  </div>
</template>

<script setup lang="ts">
import type { InputTypeHTMLAttribute } from "vue";
import "vue-select/dist/vue-select.css";
import VSelect from "vue-select";
import type { SelectItem } from "@/configs/helpers/FormatHelper";

const model = defineModel();

defineProps<{
  id?: string;
  name?: string;
  label?: string;
  required?: boolean;
  type?: InputTypeHTMLAttribute;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  multiple?: boolean;
  maxlength?: number;
  autocomplete?: boolean;
  inputClass?: any;
  errorMessage?: string;

  clearable?: boolean;
  items: Array<{ label: string; value: any } | SelectItem | string | number>;
}>();
</script>

<style>
.vs__dropdown-toggle {
  padding: 0;
  border: none;
}

.vs__search {
  border: none !important;
  padding: 0.65rem !important;
  line-height: 1.25 !important;
  background: transparent !important;
  font-size: 0.875rem !important;
  margin: 0;
  box-shadow: none !important;
}

.vs__selected {
  margin: 0;
}
</style>
