import { defineStore } from "pinia";
import * as yup from "yup";
import { fetchIncident } from "@/configs/fetches/PoliceCommandCenter/fetchIncident";
import type { typeGetQuery } from "@/configs/types/Shared/typeQuery";
import { ENUM_MUTATION_STATUS, type ENUM_MUTATION } from "@/configs/enums/enum";
import { convertToValues, removeEmptyValues } from "@/configs/helpers/MainHelper";

const storeName = `useIncidentSearchStore-${import.meta.env.VITE_APP_VERSION}`;

const isRequired = {
  startAt: {
    is: false,
    name: `startAt`,
    label: `form.startAt.label`,
    placeholder: `form.startAt.placeholder`,
    value: null,
  },
  endAt: {
    is: false,
    name: `endAt`,
    label: `form.endAt.label`,
    placeholder: `form.endAt.placeholder`,
    value: new Date(),
  },
  type: {
    is: false,
    name: `type`,
    label: `form.type.label`,
    placeholder: `form.type.placeholder`,
    value: null,
  },
  channel: {
    is: false,
    name: `type`,
    label: `form.channel.label`,
    placeholder: `form.channel.placeholder`,
    value: null,
  },
  category: {
    is: false,
    name: `category`,
    label: `form.category.label`,
    placeholder: `form.category.placeholder`,
    value: null,
  },
  topic: {
    is: false,
    name: `topic`,
    label: `form.topic.label`,
    placeholder: `form.topic.placeholder`,
    value: null,
  },
  responsibleArea: {
    is: false,
    name: `responsibleArea`,
    label: `form.responsibleArea.label`,
    placeholder: `form.responsibleArea.placeholder`,
    value: null,
  },
  keyword: {
    is: false,
    name: `keyword`,
    label: `form.keyword.label`,
    placeholder: `form.keyword.placeholder`,
    value: null,
  },
};
export const useIncidentSearchStore = defineStore(storeName, {
  state: () => ({
    fetchIncident: new fetchIncident(),
    isOpenDefault: false,
    isOpenComplete: false,
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: true,
    isRequired,
    currentPage: 1,
    totalPage: 1,
    total: 0,
    action: null as ENUM_MUTATION | null,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    tableResults: null as any,
    userRole: "",
    listType: "default",
    result: convertToValues(isRequired) as typeGetQuery,
    validationSchema: yup.object({
      startAt: isRequired.startAt.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      endAt: isRequired.endAt.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      type: isRequired.type.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      channel: isRequired.channel.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      keyword: isRequired.keyword.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
    }),
    error: {} as { [key: string]: string | null },
  }),
  actions: {
    async gotoPage(page: number, e?: MouseEvent) {
      e?.preventDefault();
      try {
        this.loading = true;
        this.currentPage = page;
        this.tableResults = await this.keyword({
          ...this.result,
          page: this.currentPage,
        });
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.totalPage = this.tableResults.totalPages;
        this.total = this.tableResults.total
        this.loading = false;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;
      }
    },
    async nextPage(e: any) {
      if (e) e.preventDefault();
      if (this.currentPage < this.totalPage) {
        try {
          this.loading = true;
          this.currentPage++;
          this.tableResults = await this.keyword({
            ...this.result,
            page: this.currentPage,
          });
          this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.totalPage = this.tableResults.totalPages;
          this.total = this.tableResults.total
          this.loading = false;
        } catch (error: any) {
          this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loading = false;
        }
      }
    },
    async prevPage(e: any) {
      if (e) e.preventDefault();
      if (this.currentPage > 1) {
        try {
          this.loading = true;
          this.currentPage--;
          this.tableResults = await this.keyword({
            ...this.result,
            page: this.currentPage,
          });
          this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.totalPage = this.tableResults.totalPages;
          this.total = this.tableResults.total
          this.loading = false;
        } catch (error: any) {
          this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loading = false;
        }
      }
    },
    async onUpdateCurrentPage(currentPage: number) {
      try {
        this.loading = true;
        this.currentPage = currentPage;
        this.tableResults = await this.keyword({
          ...this.result,
          page: this.currentPage,
        });
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.totalPage = this.tableResults.totalPages;
        this.total = this.tableResults.total
        this.loading = false;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;
      }
    },
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      this.result = removeEmptyValues(this.result, []);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });

        try {
          this.loading = true;
          this.currentPage = 1;
          this.tableResults = await this.keyword(this.result);
          this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
          this.totalPage = this.tableResults.totalPages;
          this.total = this.tableResults.total
          this.loading = false;
        } catch (error: any) {
          this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
          this.loading = false;
        }
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
      }
    },
    keyword(results: typeGetQuery) {
      const payload = {
        ...results,
        size: Number(import.meta.env.VITE_PAGE_SIZE),
      };

      switch (this.listType) {
        case 'all':
          return this.fetchIncident.AllList(payload);
        case 'me':
          return this.fetchIncident.MyList(payload);
        default:
          return this.fetchIncident.List(payload);
      }
    },
    export() {
      return this.fetchIncident.Export(this.result)
    }
  },
});
