import { defineStore } from "pinia";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import { ENUM_APP_CATEGORY_NAME, ENUM_APP_NAME } from "@/configs/enums/enum";
import { AUTH_USER_KEY } from "@/views/PoliceTouristAndAgent/Authentication/stores/useAuthStore";
import { setLocalStorage } from "@/configs/helpers/MainHelper";

const axios = axiosClient({ isFile: false }, ENUM_APP_NAME.TOURIST_POLICE, ENUM_APP_CATEGORY_NAME.TOURIST)

export type User = {
  id?: string;
  name?: string;
  email: string;
  contactNumberCountryCode?: string;
  contactNumber?: string;
  gender?: string;
  country?: string;
  nationality?: string;
  image?: string;
  consent?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export type NewUser = Pick<User, 'email' | 'contactNumberCountryCode' | 'contactNumber'> & { password: string }
export type UpdateUser = Omit<User, 'id' | 'email' | 'password' | 'createdAt' | 'updatedAt'>

export type Token = { token: string }

type State = {
  profile?: User
  user?: NewUser,
  updateUser?: UpdateUser,
}

export const useAuthStore = defineStore('auth-v2', {
  state: (): State => ({
    profile: undefined,
    user: undefined,
    updateUser: undefined,
  }),
  actions: {
    async checkDuplicateEmail(email: string, token: string = ''): Promise<boolean> {
      const config = {}
      if (token?.length) {
        Object.assign(config, { headers: { Authorization: `Bearer ${token}` } })
      }

      const { data } = await axios.post<{ duplicated: boolean }>('/v2/register/duplicate-check', { email }, config)
      return data.duplicated
    },
    async register(user: NewUser, token: string = ''): Promise<User> {
      const config = {}
      if (token?.length) {
        Object.assign(config, { headers: { Authorization: `Bearer ${token}` } })
      }

      const { data } = await axios.post<Token>('/v2/register', user, config)
      await FirebaseAuthentication.signInWithCustomToken(data)

      const profile = await this.getProfile(true)
      return profile
    },
    async getProfile(force: boolean = false): Promise<User> {
      if (force || !this.profile) {
        const { data } = await axios.get<User>('/v1/me');
        this.profile = data;
        await setLocalStorage(AUTH_USER_KEY, JSON.stringify(this.profile));
      }

      return this.profile
    },
    async updateProfile(user: UpdateUser): Promise<User> {
      await axios.put<User>('/v2/me', user)
      return this.getProfile(true)
    }
  }
})
