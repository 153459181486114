import { defineStore } from "pinia";
import { FirebaseAuthentication, Persistence, type SignInResult } from "@capacitor-firebase/authentication";
import { auth } from "@/configs/plugins/firebase";
import { fetchAuth as fetchAgentAuth } from "@/configs/fetches/PoliceTouristAndAgent/Agent/fetchAuth";
import { fetchAuth } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAuth";
import type { typeGetUser } from "@/configs/types/User/typeUser";
import { ENUM_APP_CATEGORY_NAME } from "@/configs/enums/enum";
import { removeLocalStorage, setLocalStorage, inArray } from "@/configs/helpers/MainHelper";
import { getProjectType } from "@/routers";
import { storeName as storeNamePoliceTouristAndAgent } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { storeName as storeNamePoliceCommandCenter } from "@/stores/PoliceCommandCenter/useSharedStore";

type State = {
  user: typeGetUser | null;
  token: string | null;
  deviceToken: string | null;
  registerProfile: any;
  isToggle: boolean;
  sharedStoreName: string;
};

export type Credential = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export type Type = {
  password: string;
};

export const AUTH_TOKEN_KEY = "authToken-PoliceTouristAndAgent";
export const AUTH_PROFILE_KEY = "authProfile-PoliceTouristAndAgent";
export const AUTH_USER_KEY = "authUser-PoliceTouristAndAgent";

const storeName = `useAuthStore-${import.meta.env.VITE_APP_VERSION}-PoliceTouristAndAgent`;

export const useAuthStore = defineStore(storeName, {
  state: (): State => ({
    user: null,
    token: null,
    deviceToken: null,
    registerProfile: null,
    isToggle: false,
    sharedStoreName: inArray(getProjectType(), [ENUM_APP_CATEGORY_NAME.COMMAND]) ? storeNamePoliceCommandCenter : storeNamePoliceTouristAndAgent,
  }),
  actions: {
    async setRememberMe(isRememberMe: boolean) {
      await FirebaseAuthentication.setPersistence({
        persistence: isRememberMe ? Persistence.BrowserLocal : Persistence.BrowserSession,
      });
    },
    async get() {
      const fetch = getProjectType() == ENUM_APP_CATEGORY_NAME.AGENT ? new fetchAgentAuth() : new fetchAuth();
      this.user = await fetch.Get();

      await setLocalStorage(AUTH_USER_KEY, JSON.stringify(this.user));

      return this.user;
    },
    async login({ email, password }: Credential) {
      if (!email.includes("@")) {
        email = `th.touristpolice+${email}@gmail.com`;
      }

      const result = await FirebaseAuthentication.signInWithEmailAndPassword({
        email,
        password,
      });

      try {
        const { token } = await FirebaseAuthentication.getIdToken();
        this.token = token;
      } catch (e) {
        console.error('login -> getIdToken failed', e)
        throw e
      }

      await setLocalStorage(AUTH_TOKEN_KEY, this.token || '');
      await setLocalStorage(`${this.sharedStoreName}:getProjectType`, getProjectType());

      await setLocalStorage(
        AUTH_PROFILE_KEY,
        JSON.stringify({
          email: result?.user?.email,
          phoneNumber: result?.user?.phoneNumber,
          displayName: result?.user?.displayName,
          provider: "EMAIL",
        })
      );

      try {
        return await this.get();
      } catch (e) {
        console.error('login -> get failed', e)
        throw e
      }
    },
    async loginWithCustomToken(customToken: string) {
      const result = await FirebaseAuthentication.signInWithCustomToken({ token: customToken });

      try {
        const { token } = await FirebaseAuthentication.getIdToken();
        this.token = token;
      } catch (e) {
        console.error('loginWithCustomToken -> getIdToken failed', e)
        throw e
      }

      await setLocalStorage(AUTH_TOKEN_KEY, this.token || '');
      await setLocalStorage(`${this.sharedStoreName}:getProjectType`, getProjectType());

      await setLocalStorage(
        AUTH_PROFILE_KEY,
        JSON.stringify({
          email: result?.user?.email,
          phoneNumber: result?.user?.phoneNumber,
          displayName: result?.user?.displayName,
          provider: "EMAIL",
        })
      );

      try {
        return await this.get();
      } catch (e) {
        console.error('loginWithCustomToken -> get failed', e)
        throw e
      }
    },
    async loginWithThirdParty(provider: string) {
      let result: SignInResult;
      switch (provider) {
        case "FACEBOOK":
          result = await FirebaseAuthentication.signInWithFacebook();
          break;
        case "GOOGLE":
          result = await FirebaseAuthentication.signInWithGoogle();
          break;
        case "APPLE":
          result = await FirebaseAuthentication.signInWithApple();
          break;
        case "PHONE":
          result = await new Promise<SignInResult>(async (resolve) => {
            await FirebaseAuthentication.addListener("phoneCodeSent", async (event) => {
              // Ask the user for the SMS code
              const verificationCode = window.prompt("Please enter the verification code that was sent to your mobile device.");
              // Confirm the verification code
              const result = await FirebaseAuthentication.confirmVerificationCode({
                verificationId: event.verificationId,
                verificationCode: verificationCode || "",
              });

              resolve(result);
            });

            await FirebaseAuthentication.addListener("phoneVerificationCompleted", async (event) => {
              resolve(event);
            });

            const phoneNumber = window.prompt("Please add your phone number");
            await FirebaseAuthentication.signInWithPhoneNumber({
              phoneNumber: phoneNumber || "",
            });
          });
          break;
        default:
          return;
      }

      const { token } = await FirebaseAuthentication.getIdToken();
      this.token = token;
      await setLocalStorage(AUTH_TOKEN_KEY, this.token || "");

      await setLocalStorage(
        AUTH_PROFILE_KEY,
        JSON.stringify({
          email: result?.user?.email,
          phoneNumber: result?.user?.phoneNumber,
          displayName: result?.user?.displayName,
          provider,
        })
      );

      try {
        await this.get();
      } catch (e) {
        await setLocalStorage(
          AUTH_PROFILE_KEY,
          JSON.stringify({
            email: result?.user?.email,
            phoneNumber: result?.user?.phoneNumber,
            displayName: result?.user?.displayName,
            provider,
          })
        );
      }
    },
    async linkWithThirdParty(provider: string) {
      let result: SignInResult;
      switch (provider) {
        case "FACEBOOK":
          result = await FirebaseAuthentication.linkWithFacebook();
          break;
        case "GOOGLE":
          result = await FirebaseAuthentication.linkWithGoogle();
          break;
        case "APPLE":
          result = await FirebaseAuthentication.linkWithApple();
          break;
        case "PHONE":
          result = await new Promise<SignInResult>(async (resolve) => {
            await FirebaseAuthentication.addListener("phoneCodeSent", async (event) => {
              // Ask the user for the SMS code
              const verificationCode = window.prompt("Please enter the verification code that was sent to your mobile device.");
              // Confirm the verification code
              const result = await FirebaseAuthentication.confirmVerificationCode({
                verificationId: event.verificationId,
                verificationCode: verificationCode || "",
              });

              resolve(result);
            });

            await FirebaseAuthentication.addListener("phoneVerificationCompleted", async (event) => {
              resolve(event);
            });

            const phoneNumber = window.prompt("Please add your phone number");
            await FirebaseAuthentication.signInWithPhoneNumber({
              phoneNumber: phoneNumber || "",
            });
          });
          break;
        default:
          return;
      }

      const { token } = await FirebaseAuthentication.getIdToken();
      this.token = token;

      await setLocalStorage(AUTH_TOKEN_KEY, this.token || "");

      await setLocalStorage(
        AUTH_PROFILE_KEY,
        JSON.stringify({
          email: result?.user?.email,
          phoneNumber: result?.user?.phoneNumber,
          displayName: result?.user?.displayName,
          provider,
        })
      );

      try {
        await this.get();
      } catch (e) {
        await setLocalStorage(
          AUTH_PROFILE_KEY,
          JSON.stringify({
            email: result?.user?.email,
            phoneNumber: result?.user?.phoneNumber,
            displayName: result?.user?.displayName,
            provider,
          })
        );
      }
    },
    addToken(token: string) {
      const fetch = getProjectType() == ENUM_APP_CATEGORY_NAME.AGENT ? new fetchAgentAuth() : new fetchAuth();

      return fetch.AddDevice({
        deviceToken: token,
      });
    },
    async logout() {
      await FirebaseAuthentication.signOut();
      await auth.signOut();
      removeLocalStorage(AUTH_TOKEN_KEY);
      removeLocalStorage(AUTH_PROFILE_KEY);
      removeLocalStorage(AUTH_USER_KEY);
      removeLocalStorage(`${this.sharedStoreName}:mode`);
      removeLocalStorage(`${this.sharedStoreName}:submenuOpen`);
      removeLocalStorage(`${this.sharedStoreName}:getProjectType`);
      // removeLocalStorage(`${this.sharedStoreName}:locale`);
      removeLocalStorage(`${this.sharedStoreName}:deviceToken`);

      this.token = null;
      this.user = null;
      this.registerProfile = null;
    },
  },
  getters: {
    displayName: ({ user }: State): string => {
      const [content] = user?.content || [];
      return content?.shortName || "";
    },
    displayRole: ({ user }: State): string => {
      return user?.role || "-";
    },
  },
});
